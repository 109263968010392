import React, { Fragment, useEffect } from 'react'
import { useColorScheme } from '../../store/colorSchemeStore';

const ColorSheme = () => {

    const color = useColorScheme((state)=> state.bgColor)
    const setColor = useColorScheme((state)=> state.setBg)
    const activeTextColor = useColorScheme((state)=> state.activeTextColor)
    const setActiveTextColor = useColorScheme((state)=> state.setActiveTextColor)
    const assignBgSelected = useColorScheme((state)=> state.assignBgSelected)
    const assignBgNonSelected = useColorScheme((state)=> state.assignBgNonSelected)
    const setAssignBgSelected = useColorScheme((state)=> state.setAssignBgSelected)
    const setAssignBgNonSelected = useColorScheme((state)=> state.setAssignBgNonSelected)
    const fontFamily = useColorScheme((state)=> state.fontFamily)
    const setFontFamily = useColorScheme((state)=> state.setFontFamily)

    useEffect(() => {
        document.documentElement.style.setProperty('--primary-color', color);
        document.documentElement.style.setProperty('--active-sidebar', activeTextColor)
        document.documentElement.style.setProperty('--assign-bg-selected', assignBgSelected)
        document.documentElement.style.setProperty('--assign-bg-non-selected', assignBgNonSelected)
        document.documentElement.style.setProperty('--primary-font', fontFamily)
    }, [color, activeTextColor, assignBgSelected, assignBgNonSelected, fontFamily]);
    
    const colorArray = [
        {
          colors: ['#03071E', '#370617', '#6A040F', '#9D0208', '#D00000', '#DC2F02', '#E85D04', '#F48C06', '#FAA307', '#FFBA08']
        },
        {
          colors: ['#202947', '#023E8A', '#0077D6', '#0077B6', '#0096C7', '#00B4D8', '#48CAE4', '#90E0EF', '#ADE8F4', '#CAF0F8']
        },
        {
          colors: ['#000000', '#005F73', '#0A9396', '#94D2BD', '#E9D8A6', '#EE9B00', '#CA6702', '#BB3E03', '#AE2012', '#9B2226']
        },
        {
          colors: ['#D9ED92', '#B5E48C', '#99D98C', '#76C893', '#52B69A', '#34A0A4', '#168AAD', '#1A759F', '#1E6091', '#184E77']
        },
        {
          colors: ['#D8F3DC', '#B7E4C7', '#95D5B2', '#74C69D', '#52B788', '#40916C', '#2D6A4F', '#1B4332', '#081C15', '#051400']
        },
        {
          colors: ['#F72585', '#B5179E', '#7209B7', '#560BAD', '#480CA8', '#3A0CA3', '#3F37C9', '#4361EE', '#4895EF', '#4CC9F0']
        },
        {
          colors: ['#F94144', '#F3722C', '#F8961E', '#F9844A', '#F9C74F', '#90BE6D', '#43AA8B', '#4D908E', '#577590', '#277DA1']
        },
        {
          colors: ['#590D22', '#800F2F', '#A4133C', '#C9184A', '#FF4D6D', '#FF758F', '#FF8FA3', '#FFB3C1', '#FFCCD5', '#FFF0F3']
        }
    ]

    const fontFamilyArray = [
        {font: "Poppins", value: "Poppins", id: 1},
        {font: "Lato", value: "Lato", id: 2},
        {font: "Roboto", value: "Roboto", id: 3},
        {font: "Montserrat", value: "Montserrat", id: 4},
        {font: "Soleil", value: "Soleil", id: 5},
        {font: "Open Sans", value: "Open Sans", id: 6},
        {font: "Bungee Tint", value: "Bungee Tint", id: 7},
        {font: "Matemasie", value: "Matemasie", id: 8},
        {font: "Kalnia Glaze", value: "Kalnia Glaze", id: 9},
        {font: "Sacramento", value: "Sacramento", id: 10},
        {font: "Sofia", value: "Sofia", id: 11},
        {font: "Nabla", value: "Nabla", id: 12},
        {font: "Raleway", value: "Raleway", id: 13},
        {font: "ProductSans", value: "ProductSans", id: 14},
    ]

    const handleChange =async(item)=>{
       setColor(item)
    }

    const handleActiveTextChange =async(item)=>{
        setActiveTextColor(item)
    }

    const handleNonSelectedAssign =async(item)=>{
        setAssignBgNonSelected(item)
    }

    const handleSelectedAssign =async(item)=>{
        setAssignBgSelected(item)
    }

    const handleFontFamily =async(item)=>{
        setFontFamily(item)
    }

    return(
        <Fragment>
            <div className='text-[14px] font-semibold mb-2'>
                Background Colors
            </div>
            <div className='grid grid-cols-12 gap-4'>
                {
                    colorArray.map((color, index) => (
                        <div key={index} className='col-span-3 max-[1495px]:col-span-4 max-[1399px]:col-span-6 w-full h-[125px] rounded-[10px] overflow-hidden flex flex-wrap'>
                        {color?.colors?.map((item, index) => (
                            <button
                            onClick={()=>{handleChange(item)}}
                            key={index}
                            className="flex-1 h-full"
                            style={{backgroundColor: item}}
                            />
                        ))}
                        </div>
                    ))
                }
            </div>
            <div className='text-[14px] font-semibold my-4'>
                Sidebar Active Text Colors
            </div>
            <div className='grid grid-cols-12 gap-4'>
                {
                    colorArray.map((color, index) => (
                        <div key={index} className='col-span-3 max-[1495px]:col-span-4 max-[1399px]:col-span-6 w-full h-[125px] rounded-[10px] overflow-hidden flex flex-wrap'>
                        {color?.colors?.map((item, index) => (
                            <button
                            onClick={()=>{handleActiveTextChange(item)}}
                            key={index}
                            className="flex-1 h-full"
                            style={{backgroundColor: item}}
                            />
                        ))}
                        </div>
                    ))
                }
            </div>
            <div className='text-[14px] font-semibold my-4'>
                Assign Modal Non Selected Colors
            </div>
            <div className='grid grid-cols-12 gap-4'>
                {
                    colorArray.map((color, index) => (
                        <div key={index} className='col-span-3 max-[1495px]:col-span-4 max-[1399px]:col-span-6 w-full h-[125px] rounded-[10px] overflow-hidden flex flex-wrap'>
                        {color?.colors?.map((item, index) => (
                            <button
                            onClick={()=>{handleNonSelectedAssign(item)}}
                            key={index}
                            className="flex-1 h-full"
                            style={{backgroundColor: item}}
                            />
                        ))}
                        </div>
                    ))
                }
            </div>
            <div className='text-[14px] font-semibold my-4'>
                Assign Modal Selected Colors
            </div>
            <div className='grid grid-cols-12 gap-4'>
                {
                    colorArray.map((color, index) => (
                        <div key={index} className='col-span-3 max-[1495px]:col-span-4 max-[1399px]:col-span-6 w-full h-[125px] rounded-[10px] overflow-hidden flex flex-wrap'>
                        {color?.colors?.map((item, index) => (
                            <button
                            onClick={()=>{handleSelectedAssign(item)}}
                            key={index}
                            className="flex-1 h-full"
                            style={{backgroundColor: item}}
                            />
                        ))}
                        </div>
                    ))
                }
            </div>
            <div className='text-[14px] font-semibold my-4'>
                <div className='text-[14px] font-semibold my-4'>
                    Select Font
                </div>
                <div className='grid grid-cols-12 gap-3'>
                    {
                        fontFamilyArray?.map((item, index)=> (
                            <div
                            onClick={()=>{handleFontFamily(item.value)}}
                            key={index}
                            style={{fontFamily: item?.value}}
                            className='text-[20px] col-span-2 bg-white shadow-lg h-20 rounded-[10px] flex justify-center items-center'>
                                {item?.font}
                            </div>
                        ))
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default ColorSheme
