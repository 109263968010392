import React, { useRef, useState } from 'react'
import { savePDF } from '@progress/kendo-react-pdf';
import { userStore } from '../../store/userStore';
import { BASE_URL } from '../../apis/api';

const PdfForm = () => {

  const pdfExportComponent = useRef(null);

  const store = userStore().appAllData
  
  const exportPDF = () => {
    if (pdfExportComponent.current) {
      savePDF(pdfExportComponent.current, { paperSize: 'A4', fileName: 'form.pdf' });
    }
  };

  return (
    <div style={{minHeight:"1000px", background:'#f0f0f0'}} className="content-area">

      <button onClick={exportPDF}>Export PDF</button>

      <div className='pdf-container'>
        <div className='pdf-form'>
          <div className='pdf-content' ref={pdfExportComponent}>
            <div className='pdf-head image'>
              <img src={require('../../assets/images/pdfform.png')} />
            </div>
            <div className='pdf-header title-header'>
                <div>REGISTRATION FORM</div>
            </div>
            <div className='pdf-fields mt-2'>
              <div className='pdf-common-fields'>
                <div className='pdf-label'>
                    <div>Candidate ID :</div>
                    <div>Date (dd/mm/yyyy) :</div>
                </div>
                <div className='pdf-value'>
                    <div>GB012521</div>
                    <div>09-10-2023</div>
                </div>
              </div>
              <div className='pdf-applicant imgage-client'>
                  <img src={`${BASE_URL}/api/fileuploadcommon/${store.photo}`} />
              </div>
            </div>
            <div className='pdf-instruction mt-3'>
              <div className='pdf-instruction-text'>
                  Details written here in should be legible, readable and true, further please do note that you alone will be<br />
                  responsible for the consequences arising out of any false and incorrect information given by you here in
              </div> 
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default PdfForm

