import React, { Fragment, useEffect, useState } from 'react'
import authQueries from '../../../queries/authQueries'
import { Link } from 'react-router-dom'
import LoaderCommon from '../../Common/LoaderCommon'
import moment from 'moment';
import { Empty } from 'antd';
import {getCurrentFinancialYear, getNextFinancialYear, getPreviousFinancialYear} from '../../Common/financialYear';
import { IconTrophy } from '@tabler/icons-react';
import { Radio } from "@nextui-org/react";

const LeadStatusReport = () => {

    const today = new Date()
    const [result, setResult] = useState([])
     const [refferalFields, setRefferalFields] = useState([]);

    const [entryDate, setEntryDate] = useState(new Date().toISOString().split('T')[0]);
    let freswalkintoday=0;
    let existingwalkintoday=0;
    let totalwalkintoday=0;
    let freswalkinmonth=0;
    let existingwalkinmonth=0;
    let totalwalkinmonth=0;
    let freswalkinyear=0;
    let existingwalkinyear=0;
    let totalwalkinyear=0;




    const currentFinancialYear = getCurrentFinancialYear()
    const nextFinancialYear = getNextFinancialYear()
    const previousFinancialYear = getPreviousFinancialYear()

    const leadstatusreport = authQueries.useLeadStatusReport(
        (res)=>{
            setResult(res)
            console.log("res",res?.data[0])
        }
    )

    const LeadstatusList = authQueries.useLeadstatusList(
        (res) => {
            setRefferalFields(res?.referralFields);
        }
    );



    useEffect(()=>{
        LeadstatusList.mutateAsync({entry_date: entryDate});
        leadstatusreport.mutateAsync();
       
    },[])

    const reverseReferralField = (formattedRef) => {
        if (!formattedRef.startsWith('ref_')) return null; // Ensure prefix exists
    
        return formattedRef
            .slice(4) // Remove "ref_" prefix
            .replace(/_/g, ' ') // Replace underscores with spaces
            .split(' ') // Split words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
            .join(' '); // Join words back
    };

    const calculateColumnTotals = (data, prefix) => {
        if (!data || !data[0]) return {};
        
        return refferalFields.reduce((totals, field) => {
          totals[field] = data[0].reduce((sum, item) => 
            sum + parseInt(item[`${prefix}_${field}`] || 0), 0
          );
          return totals;
        }, {});
      };
    
      const renderTotalRow = () => {
        if (!result?.data?.[0]) return null;
    
        const todayTotals = calculateColumnTotals(result.data, 'tdy');
        const mtdTotals = calculateColumnTotals(result.data, 'mtd');
        const ytdTotals = calculateColumnTotals(result.data, 'ytd');
    
        const grandTotalToday = Object.values(todayTotals).reduce((a, b) => a + b, 0);
        const grandTotalMTD = Object.values(mtdTotals).reduce((a, b) => a + b, 0);
        const grandTotalYTD = Object.values(ytdTotals).reduce((a, b) => a + b, 0);
    
        return (
          <tr className="font-bold bg-gray-50">
            <td className="border p-2 text-sm">-</td>
            <td className="border p-2 text-sm">Total</td>
    
            {refferalFields.map((field, idx) => (
              <td key={idx} className="border p-2 text-sm text-center bg-rose-50/30">
                {todayTotals[field]}
              </td>
            ))}
            <td className="border p-2 text-sm text-center bg-rose-100/30">
              {grandTotalToday}
            </td>
    
            {refferalFields.map((field, idx) => (
              <td key={idx} className="border p-2 text-sm text-center bg-amber-50/30">
                {mtdTotals[field]}
              </td>
            ))}
            <td className="border p-2 text-sm text-center bg-amber-100/30">
              {grandTotalMTD}
            </td>
    
            {refferalFields.map((field, idx) => (
              <td key={idx} className="border p-2 text-sm text-center bg-emerald-50/30">
                {ytdTotals[field]}
              </td>
            ))}
            <td className="border p-2 text-sm text-center bg-emerald-100/30">
              {grandTotalYTD}
            </td>
          </tr>
        );
      };


  return (
    <div className="w-full">
    <div className="breadcrumb-and-otherbuttons">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
          <li className="breadcrumb-item active">Lead Status Report</li>
        </ol>
      </nav>
    </div>
    
    <div className="mx-4">
      <div className="flex items-center gap-2 mb-4">
        <IconTrophy size={25} className="text-purple-600" />
        <h2 className="text-lg font-medium">Lead Status Report</h2>
      </div>

      <div className="bg-white rounded-lg shadow-sm">
        <div className="p-4">
          {leadstatusreport?.isLoading ? (
            <LoaderCommon />
          ) : (
            <Fragment>
              {result?.data?.length > 0 ? (
                <div className="overflow-x-auto">
                  <table className="w-full border-collapse border">
                    <thead>
                      <tr>
                        <th className="border p-2"></th>
                        <th className="border p-2"></th>
                        <th 
                          className="text-center border p-2 bg-rose-100 text-rose-900 font-semibold" 
                          colSpan={refferalFields.length + 1}
                        >
                          Today
                        </th>
                        <th 
                          className="text-center border p-2 bg-amber-100 text-amber-900 font-semibold" 
                          colSpan={refferalFields.length + 1}
                        >
                          MTD
                        </th>
                        <th 
                          className="text-center border p-2 bg-emerald-100 text-emerald-900 font-semibold" 
                          colSpan={refferalFields.length + 1}
                        >
                          YTD
                        </th>
                      </tr>

                      <tr>
                        <th className="border p-2 bg-gray-50">Sl No</th>
                        <th className="border p-2 bg-gray-50 min-w-[100px]">Country</th>

                        {refferalFields?.map((field, index) => (
                          <th key={index} className="border p-2 bg-rose-50 text-rose-900 text-sm">
                            {reverseReferralField(field)}
                          </th>
                        ))}
                        <th className="border p-2 bg-rose-200 text-rose-900 font-medium">Total</th>

                        {refferalFields?.map((field, index) => (
                          <th key={index} className="border p-2 bg-amber-50 text-amber-900 text-sm">
                            {reverseReferralField(field)}
                          </th>
                        ))}
                        <th className="border p-2 bg-amber-200 text-amber-900 font-medium">Total</th>

                        {refferalFields?.map((field, index) => (
                          <th key={index} className="border p-2 bg-emerald-50 text-emerald-900 text-sm">
                            {reverseReferralField(field)}
                          </th>
                        ))}
                        <th className="border p-2 bg-emerald-200 text-emerald-900 font-medium">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.data[0]?.map((item, index) => {
                        let totalToday = 0;
                        let totalMTD = 0;
                        let totalYTD = 0;
                        
                        return (
                          <tr key={index} className="hover:bg-gray-50">
                            <td className="border p-2 text-sm">{index + 1}</td>
                            <td className="border p-2 text-sm font-medium">{item.countryName}</td>

                            {refferalFields?.map((field, idx) => {
                              totalToday += parseInt(item['tdy_' + field] || 0);
                              return (
                                <td key={idx} className="border p-2 text-sm text-center bg-rose-50/30">
                                  {item['tdy_' + field] || 0}
                                </td>
                              );
                            })}
                            <td className="border p-2 text-sm font-medium text-center bg-rose-100/30">
                              {totalToday}
                            </td>

                            {refferalFields?.map((field, idx) => {
                              totalMTD += parseInt(item['mtd_' + field] || 0);
                              return (
                                <td key={idx} className="border p-2 text-sm text-center bg-amber-50/30">
                                  {item['mtd_' + field] || 0}
                                </td>
                              );
                            })}
                            <td className="border p-2 text-sm font-medium text-center bg-amber-100/30">
                              {totalMTD}
                            </td>

                            {refferalFields?.map((field, idx) => {
                              totalYTD += parseInt(item['ytd_' + field] || 0);
                              return (
                                <td key={idx} className="border p-2 text-sm text-center bg-emerald-50/30">
                                  {item['ytd_' + field] || 0}
                                </td>
                              );
                            })}
                            <td className="border p-2 text-sm font-medium text-center bg-emerald-100/30">
                              {totalYTD}
                            </td>
                          </tr>
                        );
                      })}
                       {renderTotalRow()}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="py-16">
                  <Empty description={<div className="text-sm">No data found!</div>} />
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </div>
  </div>
  )
}

export default LeadStatusReport


