import { io } from "socket.io-client";
import { BASE_URL } from "../apis/api";
import { userStore } from "../store/userStore";

const URL = BASE_URL;
const accessToken = userStore.getState().user.accessToken

export const socket = io(URL, {
    extraHeaders: {
        Authorization: accessToken,
    },
});