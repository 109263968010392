import React, { Fragment, useEffect, useState } from 'react'
import authQueries from '../../queries/authQueries'
import LoaderCommon from '../Common/LoaderCommon'
import { Empty, Checkbox } from 'antd'
import PaginationComponent from '../Common/PaginationComponent'
import { usePaginationStore } from '../../store/userStore'
import { useApi } from '../Common/ApiProvider'
import PdfGenerate from './PdfGenerate'
import moment from 'moment'
import { IconDownload } from '@tabler/icons-react'
import { BASE_URL } from '../../apis/api'

const FairStaffListing = () => {

    const {apiData} = useApi()
    const [result, setResult] = useState([])
    const [loader, setLoader] = useState(true)
    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);

    const applicationListing = authQueries.useFairApplicationList(
        (response) => {
            setResult(response)
            setLoader(false)
        }
    )

    useEffect(() => {
        applicationListing.mutateAsync({
            size: 50,
            page: currentPage
        })
    }, [apiData, currentPage])

    const [checkID, setCheckID] = useState([]);

    const handleChange1 = (e) => {
        let selected = []
        const {checked} = e.target
        if(checked){
            result?.data?.items?.map(id => selected.push(id.id))
        }
        setCheckID(selected)
    };
    
    const handleChange2 = (e) => {
        const {checked, value} = e.target
        const selectID = parseInt(value)
        setCheckID([...checkID, selectID])
        if(!checked){
            setCheckID(checkID.filter((item) => item !== selectID));
        }
    };


    const children = (id) => {
        return (
            <Checkbox
                checked={checkID.find(number => number === id) ? true: false}  
                onChange={handleChange2} 
                value={id} 
            />
        );
    }

    const exportFileDownload = authQueries.useExportFairStaffApplication(
        (response) => {
            if (response.filename) {
                window.open(`${BASE_URL}/api/impex/download/${response.filename}`, '_blank');
            }
        }
    )

    const exportFile = () => {
        exportFileDownload.mutateAsync()
    }
  
    

  return (
    <div>
        <div className="add_clients">

            <div id="accordion" className="add_clients_accordion_form">
                <div className="card" id="appointments_wrpr">
                    <div className="card-header flex justify-between">
                        <a className="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>
                            My Clients
                        </a>
                    </div>
                    <div id="appointment_any" className="collapse show" data-parent="#accordion">
                        <div className="card-body">


                            <div className="table-list-search-wrpr simple-search">
                                <div className="search-results-count-wrpr flex justify-between">
                                    <div>
                                        {
                                        result?.data?.totalItems > 0 ?
                                        `Search Results ${result?.data?.currentPage * 25 + 1}-${result?.data?.currentPage * 25 + result?.data?.items?.length} of ${result?.data?.totalItems}` :
                                        `O results found`
                                        }
                                    </div>
                                    <div>
                                        <button
                                        onClick={exportFile}
                                        className='btn-customized-large flex gap-2 w-[100px] h-[35px]'>
                                            <IconDownload size="16" />
                                            Export
                                        </button>
                                    </div>
                                </div>

                                {
                                    loader ? 
                                    <LoaderCommon />:
                                    result?.data?.totalItems > 0 ?
                                    <div className="table-wrpr">
                                        <div className="table-section">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <table className="table prospect-table">
                                                        <thead>
                                                            <tr className='alt-colors'>
                                                                {/* <th width="30">
                                                                    <div style={{marginLeft:'10px'}}>
                                                                        <Checkbox
                                                                        checked={result.data?.items?.length === checkID.length}
                                                                        indeterminate={result?.data?.items?.length !== checkID.length && checkID.length > 0}
                                                                        onChange={handleChange1}
                                                                        />
                                                                    </div>
                                                                </th> */}
                                                                <th width="30">#</th>
                                                                <th>Applicant Name</th>
                                                                <th>Mobile No</th>
                                                                <th>Email</th>
                                                                <th>Branch Office</th>
                                                                <th>Reference</th>
                                                                <th>Place</th>
                                                                <th>Create Date</th>
                                                                <th width='150'>&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="ui-sortable">
                                                            {result?.data?.items?.map((item, index) => (
                                                                <Fragment key={index}>
                                                                    <tr>
                                                                        {/* <th>
                                                                            <div style={{marginLeft:'10px'}}>
                                                                                {children(item?.id)}
                                                                            </div>
                                                                        </th> */}
                                                                        <th className="">{(index + 1) + (result.data?.currentPage * 50)}</th>
                                                                        <td>{item?.name}</td>
                                                                        <td>{item?.mobile}</td>
                                                                        <td>{item?.email}</td>
                                                                        <td>{item?.office?.name}</td>
                                                                        <td>{item?.reference}</td>
                                                                        <td>{item?.place}</td>
                                                                        <td>{moment(item?.createdAt).format("Do MMMM YYYY")}</td>
                                                                        <td>
                                                                            <PdfGenerate item={item} />
                                                                        </td>
                                                                    </tr>   
                                                                </Fragment>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className='mt-24 mb-24'>
                                        <Empty
                                        description={<div className="text-sm text-blue-700" style={{fontFamily: "var(--primary-font)"}}>Clients not found!</div>}
                                        />
                                    </div>
                                }

                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
  )
}

export default FairStaffListing