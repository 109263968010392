import React,{useState,useEffect, Fragment} from 'react'
import authQueries from '../../queries/authQueries';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Controller, Resolver, SubmitHandler, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from 'antd';
import { AutoCompleteAntd, AutoCompleteChild } from '../Clients/AddClients/AutoComplete';
import { userStore } from '../../store/userStore';
import { Card } from '@nextui-org/react';
import { BASE_URL } from '../../apis/api';

const UserAdd = () => {


    const validationSchema = yup.object({
      username: yup
        .string('⚠ Enter User Name')
        .required('⚠ User Name is required')
        .min(2,'⚠ User Name must be more than 2 characters '),
      password: yup
        .string('⚠ Enter Password')
        .required('⚠ Password is required')
        .min(2,'⚠ Password must be more than 2 characters '),
      cfmPassword: yup
        .string('⚠ Enter Confirm Password')
        .required('⚠ Confirm Password is required')
        .min(2,'⚠ Confirm Password must be more than 2 characters ')
        .oneOf([yup.ref('password'), null], 'Your passwords do not match.'),
      firstname: yup
        .string('⚠ Enter First Name')
        .required('⚠ First Name is required')
        .min(2,'⚠ First Name must be more than 2 characters '),
      lastname: yup
        .string('⚠ Enter Last Name')
        .required('⚠ Last Name is required')
        .min(2,'⚠ Last Name must be more than 2 characters '),
      role: yup
        .string('⚠ Enter Role')
        .required('⚠ Role is required'),
      email: yup
        .string('⚠ Enter your email')
        .email('⚠ Enter a valid email')
        .required('⚠ Email is required'),
      phone: yup
        .string('⚠ Enter Phone')
        .required('⚠ Phone is required')
        .min(10,'⚠ Phone must be 10 Numbers ')
        .max(10,'⚠ Phone must be 10 Numbers '),
      visatype: yup
          .array()
          .test('len', '⚠ Visa Type is required', (val) => val?.length > 0)
          .required('⚠ Visa Type is required'),
      office: yup
          .array()
          .test('len', '⚠ Office is required', (val) => val?.length > 0)
          .required('⚠ Visa Type is required'),
      address: yup
        .string('⚠ Enter Address')
        .required('⚠ Address is required'),
    });

    const MarketingSchema = yup.object({
      username: yup
        .string('⚠ Enter User Name')
        .required('⚠ User Name is required')
        .min(2,'⚠ User Name must be more than 2 characters '),
      password: yup
        .string('⚠ Enter Password')
        .required('⚠ Password is required')
        .min(2,'⚠ Password must be more than 2 characters '),
      cfmPassword: yup
        .string('⚠ Enter Confirm Password')
        .required('⚠ Confirm Password is required')
        .min(2,'⚠ Confirm Password must be more than 2 characters ')
        .oneOf([yup.ref('password'), null], 'Your passwords do not match.'),
      firstname: yup
        .string('⚠ Enter First Name')
        .required('⚠ First Name is required')
        .min(2,'⚠ First Name must be more than 2 characters '),
      lastname: yup
        .string('⚠ Enter Last Name')
        .required('⚠ Last Name is required')
        .min(2,'⚠ Last Name must be more than 2 characters '),
      role: yup
        .string('⚠ Enter Role')
        .required('⚠ Role is required'),
      email: yup
        .string('⚠ Enter your email')
        .email('⚠ Enter a valid email')
        .required('⚠ Email is required'),
      phone: yup
        .string('⚠ Enter Phone')
        .required('⚠ Phone is required')
        .min(10,'⚠ Phone must be 10 Numbers ')
        .max(10,'⚠ Phone must be 10 Numbers '),
      address: yup
        .string('⚠ Enter Address')
        .required('⚠ Address is required'),
    });

    const VisaManager = yup.object({
      username: yup
        .string('⚠ Enter User Name')
        .required('⚠ User Name is required')
        .min(2,'⚠ User Name must be more than 2 characters '),
      password: yup
        .string('⚠ Enter Password')
        .required('⚠ Password is required')
        .min(2,'⚠ Password must be more than 2 characters '),
      cfmPassword: yup
        .string('⚠ Enter Confirm Password')
        .required('⚠ Confirm Password is required')
        .min(2,'⚠ Confirm Password must be more than 2 characters ')
        .oneOf([yup.ref('password'), null], 'Your passwords do not match.'),
      firstname: yup
        .string('⚠ Enter First Name')
        .required('⚠ First Name is required')
        .min(2,'⚠ First Name must be more than 2 characters '),
      lastname: yup
        .string('⚠ Enter Last Name')
        .required('⚠ Last Name is required')
        .min(2,'⚠ Last Name must be more than 2 characters '),
      role: yup
        .string('⚠ Enter Role')
        .required('⚠ Role is required'),
      email: yup
        .string('⚠ Enter your email')
        .email('⚠ Enter a valid email')
        .required('⚠ Email is required'),
      phone: yup
        .string('⚠ Enter Phone')
        .required('⚠ Phone is required')
        .min(10,'⚠ Phone must be 10 Numbers ')
        .max(10,'⚠ Phone must be 10 Numbers '),
      address: yup
        .string('⚠ Enter Address')
        .required('⚠ Address is required'),
      visatype: yup
        .array()
        .test('len', '⚠ Visa Type is required', (val) => val?.length > 0)
        .required('⚠ Visa Type is required'),
    });

    const BranchManager = yup.object({
      username: yup
        .string('⚠ Enter User Name')
        .required('⚠ User Name is required')
        .min(2,'⚠ User Name must be more than 2 characters '),
      password: yup
        .string('⚠ Enter Password')
        .required('⚠ Password is required')
        .min(2,'⚠ Password must be more than 2 characters '),
      cfmPassword: yup
        .string('⚠ Enter Confirm Password')
        .required('⚠ Confirm Password is required')
        .min(2,'⚠ Confirm Password must be more than 2 characters ')
        .oneOf([yup.ref('password'), null], 'Your passwords do not match.'),
      firstname: yup
        .string('⚠ Enter First Name')
        .required('⚠ First Name is required')
        .min(2,'⚠ First Name must be more than 2 characters '),
      lastname: yup
        .string('⚠ Enter Last Name')
        .required('⚠ Last Name is required')
        .min(2,'⚠ Last Name must be more than 2 characters '),
      role: yup
        .string('⚠ Enter Role')
        .required('⚠ Role is required'),
      email: yup
        .string('⚠ Enter your email')
        .email('⚠ Enter a valid email')
        .required('⚠ Email is required'),
      phone: yup
        .string('⚠ Enter Phone')
        .required('⚠ Phone is required')
        .min(10,'⚠ Phone must be 10 Numbers ')
        .max(10,'⚠ Phone must be 10 Numbers '),
      address: yup
        .string('⚠ Enter Address')
        .required('⚠ Address is required'),
      office: yup
        .array()
        .test('len', '⚠ Office is required', (val) => val?.length > 0)
        .required('⚠ Office is required'),
    });

    const Executive = yup.object({
      username: yup
        .string('⚠ Enter User Name')
        .required('⚠ User Name is required')
        .min(2,'⚠ User Name must be more than 2 characters '),
      password: yup
        .string('⚠ Enter Password')
        .required('⚠ Password is required')
        .min(2,'⚠ Password must be more than 2 characters '),
      cfmPassword: yup
        .string('⚠ Enter Confirm Password')
        .required('⚠ Confirm Password is required')
        .min(2,'⚠ Confirm Password must be more than 2 characters ')
        .oneOf([yup.ref('password'), null], 'Your passwords do not match.'),
      firstname: yup
        .string('⚠ Enter First Name')
        .required('⚠ First Name is required')
        .min(2,'⚠ First Name must be more than 2 characters '),
      lastname: yup
        .string('⚠ Enter Last Name')
        .required('⚠ Last Name is required')
        .min(2,'⚠ Last Name must be more than 2 characters '),
      role: yup
        .string('⚠ Enter Role')
        .required('⚠ Role is required'),
      email: yup
        .string('⚠ Enter your email')
        .email('⚠ Enter a valid email')
        .required('⚠ Email is required'),
      phone: yup
        .string('⚠ Enter Phone')
        .required('⚠ Phone is required')
        .min(10,'⚠ Phone must be 10 Numbers ')
        .max(10,'⚠ Phone must be 10 Numbers '),
      address: yup
        .string('⚠ Enter Address')
        .required('⚠ Address is required'),
    });

    const Others = yup.object({
      username: yup
        .string('⚠ Enter User Name')
        .required('⚠ User Name is required')
        .min(2,'⚠ User Name must be more than 2 characters '),
      password: yup
        .string('⚠ Enter Password')
        .required('⚠ Password is required')
        .min(2,'⚠ Password must be more than 2 characters '),
      cfmPassword: yup
        .string('⚠ Enter Confirm Password')
        .required('⚠ Confirm Password is required')
        .min(2,'⚠ Confirm Password must be more than 2 characters ')
        .oneOf([yup.ref('password'), null], 'Your passwords do not match.'),
      firstname: yup
        .string('⚠ Enter First Name')
        .required('⚠ First Name is required')
        .min(2,'⚠ First Name must be more than 2 characters '),
      lastname: yup
        .string('⚠ Enter Last Name')
        .required('⚠ Last Name is required')
        .min(2,'⚠ Last Name must be more than 2 characters '),
      role: yup
        .string('⚠ Enter Role')
        .required('⚠ Role is required'),
      specific_role: yup
        .string('⚠ Enter Specific Role')
        .required('⚠ Specific Role is required'),
      email: yup
        .string('⚠ Enter your email')
        .email('⚠ Enter a valid email')
        .required('⚠ Email is required'),
      phone: yup
        .string('⚠ Enter Phone')
        .required('⚠ Phone is required')
        .min(10,'⚠ Phone must be 10 Numbers ')
        .max(10,'⚠ Phone must be 10 Numbers '),
      address: yup
        .string('⚠ Enter Address')
        .required('⚠ Address is required'),
    });


    const [errName, setErrName] = useState('')
    const [visaResult, setVisaResult] = useState([])
    const [roleResult, setRoleResult] = useState([]);
    const [rolesData, setRolesData] = useState([]);
    const [selectRole, setSelectRole] = useState(null);
    const [officeResult, setOfficeResult] = useState([]);
    const [image, setImage] = useState('')

    const navigate = useNavigate();

    const { register, reset, control, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(
          parseInt(selectRole) === 11 ? MarketingSchema : 
          (parseInt(selectRole) === 2 || parseInt(selectRole) === 20 || parseInt(selectRole) === 22) ? VisaManager : 
          (parseInt(selectRole) === 12 || parseInt(selectRole) === 23) ? BranchManager : 
          (parseInt(selectRole) === 15 || parseInt(selectRole) === 16 || parseInt(selectRole) === 17 || parseInt(selectRole) === 18 || parseInt(selectRole) === 19 || parseInt(selectRole) === 21) ? Executive :
          parseInt(selectRole) === 24 ? Others :
          validationSchema)
    });

    useEffect(() => {
        startRoleData();
    }, [selectRole]);

    useEffect(() => {
        startVisaData()
    }, []);

    useEffect(() => {
        startOfficeData()
    }, []);


    const startRoleData = async() => {
        await RoleList.mutateAsync({search: '', status: '', page:0, size:500});
    }

    const startVisaData = async() => {
        await VisaList.mutateAsync({search: '', status: '', page:0, size:500});
    }

    const startOfficeData = async() => {
        await OfficeList.mutateAsync({search: '', status: '', page:0, size:500});
    }

    const VisaList = authQueries.useVisaTypeMutationList(
        (response) => {
            setVisaResult(response)

            let dataArray = []
            for(let datas of response?.data?.items){
                const dataValues = {
                    label:datas?.description,
                    value:datas?.id
                }

                dataArray = [...dataArray, dataValues]
            }

            setVisaResult(dataArray)

        }
    );

    const OfficeList = authQueries.useOfficeMutationList(

        (response) => {

            let dataArray = []
            for(let datas of response?.data?.items){
                const dataValues = {
                    label:datas?.name,
                    value:datas?.id
                }

                dataArray = [...dataArray, dataValues]
            }

            setOfficeResult(dataArray)

        }
    );

    const RoleList = authQueries.useRoleMutationList(
        (response) => {
            setRoleResult(response)


            let roleArray = [];
            response?.data?.items?.map((item) => { 
                
                let role = {
                    value: item.id,
                    label: item.label
                }

                if(item.id !== JSON.parse(selectRole) && item.name !== 'ADMIN'){
                    roleArray = [...roleArray, role]  
                }
            });

            setRolesData(roleArray);

        }   
    
    ); 


    const AddUserdata = authQueries.useRegisterMutation(
        (response) => {
          if(response?.message === "Success"){
              navigate('/userlist')
          }else if(response?.error[0].username){
              setErrName("⚠ "+response?.error[0].username)
          }
        }
    );

    const onSubmit = (data) => {
  
      const datavalues = {
          username: data.username,
          password: data.password,
          role_id: data.role,
          srole: data.secondaryrole,
          confirmPassword: data.cfmPassword,
          firstname: data.firstname,
          lastname: data.lastname,
          image: image,
          user_type: data.user_type,
          office: data.office,
          specific_role: data.specific_role,
          phone: data.phone,
          email: data.email,
          visatype_id: data.visatype || null,
          reassign: data.reassign,
          employee_activity: data.empactivity,
          address: data.address,
          status: "ACTIVE",
          prevexp: data.prevexp,
          dob: data.dob,
          geebee_experience: data.geebee_experience
  
      };

      AddUserdata.mutateAsync(datavalues);    
  
    }

    const handleChange =(e)=>{
      const data = new FormData();
      data.append('file', e)
      imageUploadCreate.mutateAsync(data)
  }

  const imageUploadCreate = authQueries.useFileUploadCommon(
      (response) => {
          setImage(response?.file?.filename)
      }   
  );


  return (

    <div>

      <div style={{display:'flex', justifyContent:'center'}}>
          <div style={{width:'98%'}}>
              <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                      <li className="breadcrumb-item"><Link to={"/userlist/"}>Users</Link></li>
                      <li className="breadcrumb-item active" aria-current="page">Create User</li>
                  </ol>
              </nav>
          </div>
      </div>

      <div className='flex flex-col justify-center bg-white rounded-xl'>
            <div className='mt-8 ml-3' style={{display:'flex', justifyContent:'flex-start', gap:'3px', alignItems:'center'}}>
                <i onClick={()=> navigate('/userlist')} className='icon-keyboard_arrow_left' style={{fontSize:'24px', color:'#00f', cursor:'pointer'}}></i>
                <span>Create User</span>
            </div>
            <div className="profile-wrpr">
                <div id="image-preview" className='profile-img' style={{backgroundSize:"cover", 
                    backgroundImage:image?
                    `url(${BASE_URL}/api/fileuploadcommon/${image})`:`url(${require('../../assets/images/profile-avatar.jpg')})`
                    }}>
                    <label htmlFor="image-upload" id="image-label">Choose File</label>
                    <input onChange={(e)=>{handleChange(e.target.files[0])}} type="file" name="image" id="image-upload" /> 
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row p-5 !text-[12px]">
                    <div className="col-md-2">
                        <div className='form-group'>
                            <div id='parent' className='form-group'>
                                <label htmlFor="">User Name</label>
                                  <input className="form-control min-h-[40px] !text-[12px]" 
                                      {...register('username', {
                                          onChange: (e) => {setErrName('')},
                                          onBlur: (e) => {},
                                      })} 
                                      type="text" 
                                      name='username' 
                                  />
                                <div  className='error-msg'>{errors.username?.message?errors.username?.message: errName}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className='form-group'>
                            <div id='parent' className='form-group'>
                                <label htmlFor="">Password</label>
                                <input className="form-control min-h-[40px] !text-[12px]"  {...register("password")} type="password" name='password' />
                                <div  className='error-msg'>{errors.password?.message} </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className='form-group'>
                            <div id='parent' className='form-group'>
                                <label htmlFor="">Confirm Password</label>
                                <input className="form-control min-h-[40px] !text-[12px]"  {...register("cfmPassword")} type="password" name='cfmPassword' />
                                <div  className='error-msg'>{errors.cfmPassword?.message} </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className='form-group'>
                            <div id='parent' className='form-group'>
                                <label htmlFor="">First Name</label>
                                <input className="form-control min-h-[40px] !text-[12px]"  {...register("firstname")} type="text" name='firstname' />
                                <div  className='error-msg'>{errors.firstname?.message} </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className='form-group'>
                            <div id='parent' className='form-group'>
                                <label htmlFor="">Last Name</label>
                                <input className="form-control min-h-[40px] !text-[12px]"  {...register("lastname")} type="text" name='lastname' />
                                <div  className='error-msg'>{errors.lastname?.message} </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className='form-group'>
                            <div id='parent' className='form-group'>
                                <label htmlFor="">Primary Role</label>
                                <select
                                  className="form-control min-h-[40px] !text-[12px]"
                                  {...register('role', {
                                      onChange: (e) => {setSelectRole(e.target.value)},
                                      onBlur: (e) => {},
                                  })}
                                  name='role'>
                                  <option value=''>Select</option>
                                      {
                                          roleResult.data?.items?.map((item,index) => (
                                              <Fragment>
                                                  {
                                                      item.name === "ADMIN" ? null    
                                                      : 
                                                      <option key={index} value={item.id}>
                                                          {item.label}
                                                      </option>
                                                  }
                                              </Fragment>
                                          ))
                                      }
                                    
                                </select>
                                <div  className='error-msg'>{errors.role?.message} </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className='form-group'>
                            <div id='parent' className='form-group'>
                                <label htmlFor="">Secondary Role</label>
                                  <Controller
                                      name={"secondaryrole"}
                                      control={control}
                                      render={({ field: { onChange } }) => (
                                          <Select
                                              mode="multiple"
                                              allowClear
                                              style={{
                                                  width: '100%',
                                              }}
                                              placeholder="Please select"
                                              onChange={(val) => {onChange(val)}}
                                              options={rolesData}
                                          />
                                      )}
                                  />                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className='form-group'>
                            <div id='parent' className='form-group'>
                                <label htmlFor="">Specified Role</label>
                                <input className="form-control min-h-[40px] !text-[12px]"  {...register("specific_role")} type="text" name='specific_role' />
                                <div  className='error-msg'>{errors.specific_role?.message} </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className='form-group'>
                            <div id='parent' className='form-group'>
                                <label htmlFor="">Phone</label>
                                <input className="form-control min-h-[40px] !text-[12px]"  {...register("phone")} type="number" name='phone' />
                                <div  className='error-msg'>{errors.phone?.message} </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className='form-group'>
                            <div id='parent' className='form-group'>
                                <label htmlFor="">Email</label>
                                <input className="form-control min-h-[40px] !text-[12px]"  {...register("email")} type="email" name='email' />
                                <div  className='error-msg'>{errors.email?.message} </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className='form-group'>
                            <div id='parent' className='form-group'>
                                <label htmlFor="">Address</label>
                                <input className="form-control min-h-[40px] !text-[12px]"  {...register("address")} type="text" name='address' />
                                <div  className='error-msg'>{errors.address?.message} </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className='form-group'>
                            <div id='parent' className='form-group'>

                                <label htmlFor="">Visa Type :</label>

                                <Controller
                                      name={"visatype"}
                                      control={control}
                                      render={({ field: { onChange } }) => (
                                          <Select
                                              mode="multiple"
                                              allowClear
                                              filterOption={(input, option) =>
                                                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                              }
                                              style={{
                                                  width: '100%',
                                              }}
                                              placeholder="Please select"
                                              onChange={(val) => {onChange(val)}}
                                              options={visaResult}
                                          />
                                      )}
                                      // rules={{ required: true }}
                                  />
                                  <div className='error-msg'>{errors.visatype?.message}</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className='form-group'>
                            <div id='parent' className='form-group'>

                                <label htmlFor="">Office :</label>

                                <Controller
                                      name={"office"}
                                      control={control}
                                      render={({ field: { onChange } }) => (
                                          <Select
                                              mode="multiple"
                                              allowClear
                                              filterOption={(input, option) =>
                                                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                              }
                                              style={{
                                                  width: '100%',
                                              }}
                                              placeholder="Please select"
                                              onChange={(val) => {onChange(val)}}
                                              options={officeResult}
                                          />
                                      )}
                                      // rules={{ required: true }}
                                  />
                                  <div className='error-msg'>{errors.office?.message}</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className='form-group'>
                            <div id='parent' className='form-group'>
                                <label htmlFor="">Previous Experience</label>
                                <input className="form-control min-h-[40px] !text-[12px]"  {...register("prevexp")} type="text" name='prevexp' />
                                <div  className='error-msg'>{errors.prevexp?.message} </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className='form-group'>
                            <div id='parent' className='form-group'>
                                <label htmlFor="">DOB</label>
                                <input className="form-control min-h-[40px] !text-[12px]"  {...register("dob")} type="date" name='dob' />
                                <div  className='error-msg'>{errors.dob?.message} </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className='form-group'>
                            <div id='parent' className='form-group'>
                                <label htmlFor="">Geebee Experience</label>
                                <input className="form-control min-h-[40px] !text-[12px]"  {...register("geebee_experience")} type="date" name='geebee_experience' />
                                <div  className='error-msg'>{errors.geebee_experience?.message} </div>
                            </div>
                        </div>
                    </div>



                    <div className="row col-md-12">
                        <div className="col-md-12 text-center">
                            <button type='submit' className="btn btn-default btn-save">Create</button>
                        </div>
                    </div>

                </div>

            </form>
      </div>                  



    </div>
  )
}

export default UserAdd