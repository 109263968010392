import { useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';
import { set, useForm, Controller } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GanttChartSquare } from 'lucide-react';
import { Link } from 'react-router-dom';
import authQueries from '../../queries/authQueries';
import { AutoCompleteAntd, AutoCompleteAntdOffice } from '../Clients/AddClients/AutoComplete';
import toast from 'react-hot-toast';
import ExistingForm from './ExistingForm';
import { userStore } from '../../store/userStore';
import ValidationMobileModal from '../Common/ValidationMobileModal';
import PhoneInput from 'react-phone-input-2'
import parsePhoneNumberFromString from 'libphonenumber-js';

const fresherValidationSchema = yup.object({
    fname: yup.string().required('⚠ First Name is required'),
    phone: yup.string().required('⚠ Phone Number is required'),
});

const existingValidationSchema = yup.object({
    fname: yup.string().required('⚠ First Name is required'),
    phone: yup.string().required('⚠ Phone Number is required'),
});


export default function WalkinForm() {
    const [office, setOffice] = useState('');
    const [prosStatus, setProsStatus] = useState('');
    const [officeData, setOfficeData] = useState([]);
    const [users, setUsers] = useState([]);
    const [formData, setFormData] = useState({});
    const [allStaff, setAllStaff] = useState([]);
    const [prospects, setProspects] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [code, setCode] = useState('in');
    const [mobile, setMobile] = useState('');
    const [mobileValid, setMobileValid] = useState('')
    const store = userStore()

    useEffect(() => {
        officeList.mutateAsync({ search: '', status: '', page: 0, size: 500 });
        AllStaffList.mutateAsync();
        prospectList.mutateAsync({ search: '', status: '', page: 0, size: 500 });
    }, [])


    useEffect(() => {
        handleOfficeSelect();
    }, [office])

    // Separate validation schemas for each form
    const {
        register: fresherRegister,
        handleSubmit: fresherHandleSubmit,
        setValue: fresherSetValue,
        control: fresherControl,
        formState: { errors: fresherErrors }
    } = useForm({
        resolver: yupResolver(fresherValidationSchema)
    });

    const {
        register: existingRegister,
        handleSubmit: existingHandleSubmit,
        setValue: existingSetValue,
        control: existingControl,
        formState: { errors: existingErrors }
    } = useForm({
        resolver: yupResolver(existingValidationSchema)
    });

    // Reset fresher form fields
    const resetFresherForm = () => {
        fresherSetValue('fname', '');
        fresherSetValue('lname', '');
        fresherSetValue('phone', '+91');
        fresherSetValue('email', '');
    };

    // Reset existing form fields
    const resetExistingForm = () => {
        existingSetValue('fname', '');
        existingSetValue('lname', '');
        existingSetValue('phone', '+91');
    };


    const onFresherSubmit = (data) => {
        setIsLoading(true);
        const dataValues = {
            first_name: data.fname,
            last_name: data.lname,
            phone: data.phone,
            email: data.email,
            purpose: data.purpose,
            reference: data.reference,
            office_id: office,
            assign_to_counselor: data.assign,
            created_by: store?.user?.id,
            walkin_status: "FRESHER"
        };

        AddWalkin.mutateAsync(dataValues);
    };

    const onExistingSubmit = (data) => {
        setIsLoading(true);
        const dataValues = {
            first_name: data.fname,
            last_name: data.lname,
            phone: data.phone,
            office_id: office,
            assign_to_counselor: data.assign,
            created_by: store?.user?.id,
            walkin_status: "EXISING",
            walkin_attendedBy: data.attendedby,
            currentMagStatus: data.prospect_status_id
        };
        AddWalkin.mutateAsync(dataValues);
    };



    const masterList = authQueries.useApplicantFormMastersList(
        (response) => {
            setUsers(response)
            let dataArray = []
            for (let datas of response?.data?.users) {

                const dataValues = {
                    label: datas?.userdetail?.firstname + ' ' + datas?.userdetail?.lastname,
                    value: datas?.id
                }

                dataArray = [...dataArray, dataValues]
            }

            setUsers(dataArray)
        }
    );

    const AllStaffList = authQueries.useWalkinStaffList(
        (response) => {
            setAllStaff(response)
            let dataArray = []
            for (let datas of response) {

                const dataValues = {
                    label: datas?.user?.userdetail?.firstname + ' ' + datas?.user?.userdetail?.lastname,
                    value: datas?.user?.id
                }

                dataArray = [...dataArray, dataValues]
            }

            setAllStaff(dataArray)
        }
    );

    const prospectList = authQueries.useProspectList(
        (response) => {
            setProspects(response)
            let dataArray = []
            for (let datas of response?.data?.items) {

                const dataValues = {
                    label: datas?.name,
                    value: datas?.id
                }

                dataArray = [...dataArray, dataValues]
            }

            setProspects(dataArray)
        }
    );



    const officeList = authQueries.useOfficeMutationList(
        (response) => {
            setOfficeData(response)
            let dataArray = []
            for (let datas of response?.data?.items) {
                const dataValues = {
                    label: datas?.name,
                    value: datas?.id
                }

                dataArray = [...dataArray, dataValues]
            }

            setOfficeData(dataArray)
        }
    );

    const handleOfficeSelect = () => {
        masterList.mutateAsync({ visa: '', office, refferal: '' });
    };


    const AddWalkin = authQueries.useWalkincreate(
        (response) => {
            if (response?.message === "Success") {
                toast.success('Walkin Added Successfully');
                // Reset the appropriate form based on the walkin status
                if (response?.data?.walkin_status === "FRESHER") {
                    setIsLoading(false);
                    resetFresherForm();
                } else {
                    setIsLoading(false);
                    resetExistingForm();
                }
            } else {
                setIsLoading(false);
                toast.error(response?.message);
            }
        }
    );


    // Rest of your existing code for useEffect, queries, etc.

    return (
        <div className='add_clients_accordion_form' id='accordion'>
            <div className='card' id='appointments_wrpr'>
                <div className='card-header flex justify-between'>
                    <a class="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>Walk-in</a>
                </div>

                <div className='card-body'>

                    <div className="breadcrumb-and-otherbuttons">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Walk-in</li>
                            </ol>
                        </nav>
                    </div>

                    <div className="flex items-center gap-[4px] mb-4">
                        <i className="followup-icon">
                            <GanttChartSquare size="25" className='text-[#7D6FAB]' />
                        </i>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <span className="text-[16px] font-medium">Walk-in</span>
                            </div>
                        </div>
                    </div>

                    <div className="w-full max-w-5xl mx-auto p-4">
                        <Tab.Group>

                            <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
                                <Tab
                                    className={({ selected }) =>
                                        `w-full rounded-lg py-2.5 text-sm font-medium leading-5
                ${selected
                                            ? 'bg-white shadow text-blue-700'
                                            : 'text-black-100 hover:bg-white/[0.12] hover:text-blue'}`
                                    }
                                    onClick={() => setFormData(prev => ({ ...prev, walkin_status: 'FRESHER' }))}
                                >
                                    Fresh Walk-In
                                </Tab>
                                <Tab
                                    className={({ selected }) =>
                                        `w-full rounded-lg py-2.5 text-sm font-medium leading-5
                ${selected
                                            ? 'bg-white shadow text-blue-700'
                                            : 'text-black-100 hover:bg-white/[0.12] hover:text-blue'}`
                                    }
                                    onClick={() => setFormData(prev => ({ ...prev, walkin_status: 'EXISTING' }))}
                                >
                                    Existing Walk-In
                                </Tab>

                            </Tab.List>

                            <Tab.Panels className="mt-6">
                                <Tab.Panel>
                                    <form onSubmit={fresherHandleSubmit(onFresherSubmit)}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">First Name<em className="mandatory">*</em></label>
                                                        <input className="form-control" {...fresherRegister("fname")} type="text" name='fname' />
                                                        <div className="text-danger">{fresherErrors.fname?.message}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Last Name</label>
                                                        <input className="form-control" {...fresherRegister("lname")} type="text" name='lname' />
                                                        <div className="text-danger">{fresherErrors.lname?.message}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="col-md-6">
                      <div className='form-group'>
                          <div id='parent' className='form-group'>
                              <label htmlFor="">Phone<em className="mandatory">*</em></label>
                              <input className="form-control"  {...fresherRegister("phone")}  type="tel" name='phone' />     
                              <div className="text-danger">{fresherErrors.phone?.message}</div>                
                          </div>
                      </div>
                  </div> */}

                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <div id='parent' className='form-group'>
                                                    <label htmlFor="">Phone<em className="mandatory">*</em></label>
                                                        <Controller
                                                            name='phone'
                                                            control={fresherControl}
                                                            className="form-control"
                                                            render={({ field: { onChange, value } }) => (
                                                                <PhoneInput
                                                                    enableSearch={true}
                                                                    country={code}
                                                                    value={value}
                                                                    onChange={(value, country, e, formattedValue) => {
                                                                    const digitsOnly = formattedValue.replace(/\D/g, ''); // Remove non-digit characters
                                                                    const countryCodeLength = country.countryCode.length; // Get length of country code
                                                                    const extractedPhoneNumber = digitsOnly.slice(countryCodeLength);
                                                                    setCode(country.countryCode)
                                                                    const parsedPhoneNumber = parsePhoneNumberFromString(`+${value}`);
                                                                    if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
                                                                        onChange(value)
                                                                        setMobile(extractedPhoneNumber);
                                                                        setMobileValid('')
                                                                    }
                                                                    }}
                                                                />
                                                                )}
                                                        rules={{ required: true }}
                                                        />
                                                        <div className="text-danger">{fresherErrors.phone?.message}</div>               
                                                </div>
                                            </div>
                                        </div>

                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Email</label>
                                                        <input className="form-control"  {...fresherRegister("email")} type="email" name='email' />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Purpose</label>
                                                        <select {...fresherRegister("purpose")} className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" name='name'>
                                                            <option value="Study" selected>Study</option>
                                                            <option value="Migration">Migration</option>
                                                            <option value="Others">Others</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Reference</label>
                                                        <input className="form-control" value={"Walk-in"} {...fresherRegister("reference")} type="text" name='reference' />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Branch</label>
                                                        <AutoCompleteAntdOffice
                                                            setOffice={setOffice}
                                                            control={fresherControl}
                                                            data={officeData}
                                                            name={"office_id"}
                                                            value={{ label: 'Select Branch', value: null }}
                                                            error={fresherRegister.office_id}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Assign to Counselor</label>
                                                        <select className="form-control" name='assign'  {...fresherRegister("assign")}>
                                                            <option value=''>Select Counselor</option>
                                                            {
                                                                users?.map((item, index) => (
                                                                    <option key={index} value={item?.value}>
                                                                        {item?.label}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex justify-end">
                                            {
                                                isLoading ? (
                                                    <button
                                                        type="submit"
                                                        style={{ backgroundColor: '#323a51' }}
                                                        className="px-4 py-2 text-white rounded-md hover:opacity-90"
                                                    >
                                                        Loading...
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="submit"
                                                        style={{ backgroundColor: '#323a51' }}
                                                        className="px-4 py-2 text-white rounded-md hover:opacity-90"
                                                    >
                                                        Submit
                                                    </button>
                                                )
                                            }
                                            {/* <button
                    type="submit"
                    style={{backgroundColor:'#323a51'}}
                    className="px-4 py-2 text-white rounded-md hover:opacity-90"
                  >
                    Submit
                  </button> */}
                                        </div>

                                    </form>
                                </Tab.Panel>

                                <Tab.Panel>
                                    <ExistingForm
                                        handleSubmit={existingHandleSubmit}
                                        onSubmit={onExistingSubmit}
                                        setOffice={setOffice}
                                        officeData={officeData}
                                        users={users}
                                        register={existingRegister}
                                        control={existingControl}
                                        errors={existingErrors}
                                        office={office}
                                        allStaff={allStaff}
                                        prospects={prospects}
                                        setProsStatus={setProsStatus}
                                        isLoading={isLoading}
                                        code={code}
                                        setCode={setCode}
                                        setMobile={setMobile}
                                        setMobileValid={setMobileValid}

                                    />
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                </div>
            </div>
        </div>


    );
}