import React, { useEffect } from 'react'

const SamplePageCallShow = () => {

  return (
    <div className='h-[100vh] flex flex-col justify-center items-center bg-white'>
        <div className="flex flex-col justify-center items-center mb-4">
            <span>Twilio Call Recording</span>
            <audio id="audio" controls>
                <source
                src="https://api.twilio.com/2010-04-01/Accounts/AC9ad1a77821644cb5c87e3b621c2a33d1/Recordings/RE81096035e69b40f7a4e22d04aebf7df6.wav"
                type="audio/ogg"
                />
                Your browser does not support the audio element.
            </audio>
        </div>        
        <div className="flex flex-col justify-center items-center">
            <span>Smart Log Call Recording</span>
            <audio id="audio" controls>
                <source
                src="https://xtend.themagweb.com/WebAPI.xbc?streamcallwaveeasy()&callrefid=20250129170122-12B85E63-E930-4FF0-9BAB-601D5C22F856" 
                type="audio/wav"
                />
                Your browser does not support the audio element.
            </audio>
        </div>
    </div>
  )
}

export default SamplePageCallShow
