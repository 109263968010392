import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import authQueries from '../../queries/authQueries';
import LoaderCommon from "../Common/LoaderCommon";
import toast from "react-hot-toast";

const LeadStatus = () => {
    const [countries, setCountries] = useState([]);
    const [refferalFields, setRefferalFields] = useState([]);
    const [entryDate, setEntryDate] = useState(new Date().toISOString().split('T')[0]);
    const [editingCell, setEditingCell] = useState(null);
    const [countryData, setCountryData] = useState({});

    const LeadstatusList = authQueries.useLeadstatusList(
        (res) => {
            setCountries(res?.data);
            setRefferalFields(res?.referralFields);
            // Initialize countryData with current values
            const initialData = {};
            res?.data?.forEach(country => {
                initialData[country.countryid] = {};
                res?.referralFields?.forEach(field => {
                    initialData[country.countryid][field] = country[field];
                });
            });
            setCountryData(initialData);
        }
    );

    const updateLeadStatus = authQueries.useLeadStatuscreate(
        (res) => {
            if (res.success) {
                toast.success("Data updated successfully");
            }
        }
    );

    const onDateChange = (e) => {   
        setEntryDate(e.target.value);
        LeadstatusList.mutateAsync({entry_date: e.target.value});
    }

    const handleCellClick = (countryId, field) => {
        console.log("countryId",countryId, "field",field)
        setEditingCell({ countryId, field });
    }

    const handleCellChange = (e, countryId, field) => {
        const value = parseInt(e.target.value);
        setCountryData(prev => ({
            ...prev,
            [countryId]: {
                ...prev[countryId],
                [field]: value
            }
        }));
    }

    const handleBlur = () => {
        if (editingCell) {
            const payload = {
                userId: 7, // Replace with actual user ID
                entry_date: entryDate,
                leads: countries.map(country => ({
                    country: country.countryid,
                    ...countryData[country.countryid]
                }))
            };
            console.log(payload)
            updateLeadStatus.mutateAsync(payload);
            setEditingCell(null);
        }
    }

    useEffect(() => {
        LeadstatusList.mutateAsync({entry_date: entryDate});
    }, []);


    const reverseReferralField = (formattedRef) => {
        if (!formattedRef.startsWith('ref_')) return null; // Ensure prefix exists
    
        return formattedRef
            .slice(4) // Remove "ref_" prefix
            .replace(/_/g, ' ') // Replace underscores with spaces
            .split(' ') // Split words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
            .join(' '); // Join words back
    };

    return (
        <div>
            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                        <li className="breadcrumb-item active">Lead Status</li>
                    </ol>
                </nav>
            </div>
            <div className="add_clients">
                <div className="row">
                    <div className="col-md-6 appointments-hing-wrpr">
                        <i className="appointments-icon">
                            <img src={require("../../assets/images/appointments-today-icon.png")} alt="" />
                        </i>
                        <h2><span>&nbsp;</span>Lead Status</h2>
                    </div>
                    <div className="col-md-12">
                        <hr className="progress-hr" />
                    </div>
                </div>
                <div id="accordion" className="add_clients_accordion_form">
                    <div className="card">
                        <div className="card-header text-[14px]">Lead Status</div>
                        {LeadstatusList.isLoading ? (
                            <LoaderCommon />
                        ) : (
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <label htmlFor="id" className="form-label">Select Date</label>
                                        <input 
                                            type="date" 
                                            onChange={onDateChange} 
                                            value={entryDate} 
                                            className="form-control form-control-sm !min-h-[38px]" 
                                        />
                                        <div className="table-list-search-wrpr table-report-ceo overflow-auto mt-4">
                                            <div className="overflow-x-auto max-h-[800px]">
                                                <table className="table report associate-report collageprogram">
                                                <thead>
                                                    <tr>
                                                        <th className="min-w-[50px] text-center">#</th>
                                                        <th className="min-w-[100px] !text-[13px] text-center">Country</th>
                                                        {refferalFields?.map((field, index) => (
                                                            <th key={index} className="min-w-[50px] !text-[13px] text-center py-2">
                                                                {reverseReferralField(field)}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                    <tbody>
                                                        {countries?.map((country, index) => (
                                                            <tr key={country.countryid}>
                                                                <td className="!text-[13px] text-center">{index + 1}</td>
                                                                <td className="!text-[13px] text-center py-2">{country.name}</td>
                                                                {refferalFields?.map((field) => (
                                                                    <td 
                                                                        key={`${country.countryid}-${field}`}
                                                                        className="!text-[13px] text-center"
                                                                    >
                                                                        {editingCell?.countryId === country.countryid && editingCell?.field === field ? (
                                                                            <input
                                                                                type="number"
                                                                                value={countryData[country.countryid]?.[field]}
                                                                                onChange={(e) => handleCellChange(e, country.countryid, field)}
                                                                                onBlur={handleBlur}
                                                                                autoFocus
                                                                                className="text-center"
                                                                            />
                                                                        ) : (
                                                                            <div 
                                                                                onClick={() => handleCellClick(country.countryid, field)}
                                                                                className="cursor-pointer hover:bg-gray-100 p-1"
                                                                            >
                                                                                {countryData[country.countryid]?.[field] || 0}
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LeadStatus;