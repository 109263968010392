import React from 'react'
import { AutoCompleteAntd, AutoCompleteAntdOffice } from '../Clients/AddClients/AutoComplete'
import PhoneInput from 'react-phone-input-2'
import parsePhoneNumberFromString from 'libphonenumber-js';
import { Controller } from "react-hook-form";

export default function ExistingForm({
    handleSubmit,
    onSubmit,
    setOffice,
    officeData,
    users,
    register,
    control,
    errors,
    allStaff,
    prospects,
    setProsStatus,
    isLoading,
    code,
    setCode,
    setMobile,
    setMobileValid
}) {

  return (
    <div>
        <form onSubmit={handleSubmit(onSubmit)}>
             <div className="row">

              <div className="col-md-6">
                      <div className='form-group'>
                          <div id='parent' className='form-group'>
                              <label htmlFor="">First Name<em className="mandatory">*</em></label>
                              <input className="form-control" {...register("fname")}   type="text" name='fname' />   
                              <div className="text-danger">{errors.fname?.message}</div>                  
                          </div>
                      </div>
                  </div>

                  <div className="col-md-6">
                    <div className='form-group'>
                        <div id='parent' className='form-group'>
                            <label htmlFor="">Last Name<em className="mandatory">*</em></label>
                            <input className="form-control" {...register("lname")}   type="text" name='lname' />       
                            <div className="text-danger">{errors.lname?.message}</div>              
                        </div>
                    </div>
                </div>

                {/* <div className="col-md-6">
                    <div className='form-group'>
                        <div id='parent' className='form-group'>
                            <label htmlFor="">Phone<em className="mandatory">*</em></label>
                            <input className="form-control"  {...register("phone")}  type="tel" name='phone' />     
                            <div className="text-danger">{errors.phone?.message}</div>                
                        </div>
                    </div>
                </div> */}

                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <div id='parent' className='form-group'>
                                                    <label htmlFor="">Phone<em className="mandatory">*</em></label>
                                                        <Controller
                                                            name='phone'
                                                            control={control}
                                                            className="form-control"
                                                            render={({ field: { onChange, value } }) => (
                                                                <PhoneInput
                                                                    enableSearch={true}
                                                                    country={code}
                                                                    value={value}
                                                                    onChange={(value, country, e, formattedValue) => {
                                                                    const digitsOnly = formattedValue.replace(/\D/g, ''); // Remove non-digit characters
                                                                    const countryCodeLength = country.countryCode.length; // Get length of country code
                                                                    const extractedPhoneNumber = digitsOnly.slice(countryCodeLength);
                                                                    setCode(country.countryCode)
                                                                    const parsedPhoneNumber = parsePhoneNumberFromString(`+${value}`);
                                                                    if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
                                                                        onChange(value)
                                                                        setMobile(extractedPhoneNumber);
                                                                        setMobileValid('')
                                                                    }
                                                                    }}
                                                                />
                                                                )}
                                                        rules={{ required: true }}
                                                        />
                                                        <div className="text-danger">{errors.phone?.message}</div>   
                                                </div>
                                            </div>
                                        </div>

                <div className="col-md-6">
                    <div className='form-group'>
                        <div id='parent' className='form-group'>
                        <label htmlFor="">Branch</label>
                            <AutoCompleteAntdOffice
                            setOffice={setOffice}
                            control={control}
                            data={officeData} 
                            name={"office_id"}
                            value={{label: 'Select Branch', value: null}}
                            error={errors.office_id}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className='form-group'>
                        <div id='parent' className='form-group'>
                        <label htmlFor="">File Owned By Counselor</label>
                        <select  className="form-control" name='assign'  {...register("assign")}>
                            <option value=''>Select Counselor</option>
                                {
                                    users?.map((item, index)=> (
                                        <option key={index} value={item?.value}>
                                            {item?.label}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className='form-group'>
                        <div id='parent' className='form-group'>
                        <label htmlFor="">Walk –in Attended By</label>
                        <select  className="form-control" name='attendedby'  {...register("attendedby")}>
                            <option value=''>Select Staff</option>
                                {
                                    allStaff?.map((item, index)=> (
                                        <option key={index} value={item?.value}>
                                            {item?.label}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className='form-group'>
                        <div id='parent' className='form-group'>
                        <label htmlFor="">Current  Status</label>
                            <AutoCompleteAntd
                            setProsStatus={setProsStatus}
                            control={control}
                            data={prospects} 
                            name={"prospect_status_id"}
                            value={{label: 'Select Status', value: null}}
                            error={errors.prospect_status_id}
                            />
                        </div>
                    </div>
                </div>



              </div>
              
              <div className="flex justify-end">
              {
                    isLoading ? (
                    <button
                        type="submit"
                        style={{backgroundColor:'#323a51'}}
                        className="px-4 py-2 text-white rounded-md hover:opacity-90"
                    >
                        Loading...
                    </button>
                    ) : (
                    <button
                        type="submit"
                        style={{backgroundColor:'#323a51'}}
                        className="px-4 py-2 text-white rounded-md hover:opacity-90"
                    >
                        Submit
                    </button>
                    )
                }
              </div>
            </form>
    </div>
  )
}
