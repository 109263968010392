import React, { Fragment, useEffect, useState } from 'react'
import authQueries from '../../../queries/authQueries'
import { Link } from 'react-router-dom'
import LoaderCommon from '../../Common/LoaderCommon'
import moment from 'moment';
import { Empty } from 'antd';
import {getCurrentFinancialYear, getNextFinancialYear, getPreviousFinancialYear} from '../../Common/financialYear';
import { IconTrophy } from '@tabler/icons-react';
import { userStore } from '../../../store/userStore';
import SelectSearch from "../../Common/SelectSearch";
import RegisteredCheckSearch from '../../Common/RegisteredCheckSearch';
import { useAdvancedSearch } from '../../../store/advancedSearchStore';
import AdvancedSearch from '../../Common/AdvancedSearch';
import { Loading } from "@nextui-org/react";
import { BASE_URL } from '../../../apis/api';


const ClientExport = () => {


    function getCurrentFinancialYearDates() {
        var today = new Date();
        var currentYear = today.getFullYear();
        var financialYearStart = new Date(currentYear, 3, 1); // April 1st is month 3 (0-indexed)
        if (today < financialYearStart) {
            financialYearStart = new Date(currentYear - 1, 3, 1); // April 1st is month 3 (0-indexed)
        }
        var nextYear = currentYear + 1;
        var financialYearEnd = new Date(nextYear, 2, 31); // March 31st is month 2 (0-indexed)
        if (financialYearEnd < today) {
            financialYearEnd = new Date(currentYear, 2, 31); // March 31st is month 2 (0-indexed)
        }
        var formattedStartDate = formatDate(financialYearStart);
        var formattedEndDate = formatDate(financialYearEnd);
        return {
            startDate: formattedStartDate,
            endDate: formattedEndDate
        };
    }
    var financialYearDates = getCurrentFinancialYearDates();

    function formatDate(date) {
        var year = date.getFullYear();
        var month = ('0' + (date.getMonth() + 1)).slice(-2); 
        var day = ('0' + date.getDate()).slice(-2);
        return year + '-' + month + '-' + day;
    }


    const today = new Date()
    const store = userStore();
    const [result, setResult] = useState([])
    const [dateRange, setDateRange] = useState("CURRENT")
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate]= useState('')
    const [prosStatus, setProsStatus] = useState('')
    const counselor = useAdvancedSearch((state) => state.counselor);
    const setCounselor = useAdvancedSearch((state) => state.setCounselor);
    const registered = useAdvancedSearch((state) => state.registered);
    const setRegistered = useAdvancedSearch((state) => state.setRegistered);
    const [masterData, setMasterData] = useState({})
    const status = useAdvancedSearch((state) => state.status);
    const setStatus = useAdvancedSearch((state) => state.setStatus);
    const [loadingRow, setLoadingRow] = useState(null);
    const currentFinancialYear = getCurrentFinancialYear()
    const nextFinancialYear = getNextFinancialYear()
    const previousFinancialYear = getPreviousFinancialYear()

    const [Reg, setReg] =  useState(false);
    const [NonReg, setNonReg] =  useState(false);
    const [NonRegOnhold, setNonRegOnhold] =  useState(false);
    const [RegOnhold, setRegOnhold] =  useState(false);
    const [VisaApproved, setVisaApproved] =  useState(false);
    const [VisaRefused, setVisaRefused] =  useState(false);
    const [notInterested, setNotInterested] =  useState(false);
    const [notQualified, setNotQualified] =  useState(false);


    const clientListing = authQueries.useClientexportlistReportMutation(
        (res)=>{
            setResult(res?.data)
           
        }
    )


    const advancedMasterList = authQueries.useAdvancedSearchMutation(
        (response) => {
            setMasterData(response?.data)
        }
    );



    const clientExport = authQueries.useClientexportExcelMutation(
        (res)=>{
            window.open(`${BASE_URL}/api/report/download/${res?.filename}`, '_blank')
            console.log("res",res)
        }
    )
    
    useEffect(async ()=>{
        await clientListing.mutateAsync({from: fromDate, to: toDate, reg_status:registered, pros_status:'', counselor:'' });
        await advancedMasterList.mutateAsync();
    },[]) 

    const onSearch =()=>{
        clientListing.mutateAsync({from: fromDate, to: toDate, counselor:counselor, Reg:Reg, NonReg:NonReg, NonRegOnhold:NonRegOnhold, RegOnhold:RegOnhold, VisaApproved:VisaApproved, VisaRefused:VisaRefused, notInterested, notQualified})
    }


    const onExport =(country)=>{
        clientExport.mutateAsync({from: fromDate, to: toDate,  counselor:counselor, country, Reg:Reg, NonReg:NonReg, NonRegOnhold:NonRegOnhold, RegOnhold:RegOnhold, VisaApproved:VisaApproved, VisaRefused:VisaRefused, notInterested, notQualified})
    }

    const onExportAll =()=>{
        setLoadingRow(null)
        clientExport.mutateAsync({from: fromDate, to: toDate,  counselor:counselor, country:'', Reg:Reg, NonReg:NonReg, NonRegOnhold:NonRegOnhold, RegOnhold:RegOnhold, VisaApproved:VisaApproved, VisaRefused:VisaRefused, notInterested, notQualified})
    }

    const handlefromDate = (event) => {
        const value = event.target.value;
        setFromDate(value);
    };

    const handletoDate = (event) => {
        const value = event.target.value;
        setToDate(value);
    };


    const userSearchArry = [
        {
            label: "Counselor",
            size: 2,            
            state: counselor,
            setState: setCounselor,
            input: SelectSearch,
            value: store.user.role === "SENIORPROGRAMMANAGER" ? masterData?.seniorpgmusers : masterData?.user?.filter(user => user.roles.includes(3)),
            hidden: false,
            name: 'name',
            simple: true,
        },
    ]
    

    const chipData = [
        {
            id: 1,
            label: "Non Reg",
            state: NonReg,
            setState: setNonReg,
        },
        {
            id: 2,
            label: "Reg",
            state: Reg,
            setState: setReg,
        },
        {
            id: 3,
            label: "Non Reg onhold",
            state: NonRegOnhold,
            setState: setNonRegOnhold,
        },
        {
            id: 4,
            label: "Reg onhold",
            state: RegOnhold,
            setState: setRegOnhold,
        },
        {
            id: 5,
            label: "Visa Approved",
            state: VisaApproved,
            setState: setVisaApproved,
        },
        {
            id: 6,
            label: "Visa Refused",
            state: VisaRefused,
            setState: setVisaRefused,
        },
        {
            id: 7,
            label: "Not Interested",
            state: notInterested,
            setState: setNotInterested,
        },
        {
            id: 8,
            label: "Not Qualified",
            state: notQualified,
            setState: setNotQualified,
        },
    ]

    console.log("result",chipData)

  return (
    <div>
    <div className="breadcrumb-and-otherbuttons">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Client Export Report</li>
            </ol>
        </nav>
    </div>
    
    <div className="add_clients">
        <div className="flex items-center gap-[4px] mb-4">
            <div style={{display:'flex', flexDirection:'column'}}>
                <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                    <span className="text-[16px] font-medium">Client Export Report</span>	
                </div>
            </div>
        </div>
        <div id="accordion" className="add_clients_accordion_form mt-[10px]">
            <div  className="card" id="appointments_wrpr_employee">
                <div className="card-header">
                Client Export Report
                </div>
                <div id="appointment_any" className="collapse show" data-parent="#accordion">
                    <div className="card-body">
                <Fragment>
                    
                    <div className="grid grid-cols-12 max-[1389px]:grid-cols-10 max-[1163px]:grid-cols-8 max-[929px]:grid-cols-6 max-[585px]:grid-cols-4 max-[445px]:grid-cols-2 gap-x-6 gap-y-5 !mb-5">

                    <div className='col-span-2 flex flex-col gap-2'>
                            <label>From</label>
                            <input value={fromDate} onChange={handlefromDate}  className="form-control form-control-sm" type="date" placeholder="From" />
                    </div>
                    <div className='col-span-2 flex flex-col gap-2'>
                            <label>To</label>
                            <input  value={toDate} onChange={handletoDate}  className="form-control form-control-sm" type="date" placeholder="TO" />
                    </div>

                    {
                        userSearchArry?.map((item, index)=> {
                            if(item?.blank && !item?.hidden){
                                return(
                                <div className={`col-span-${item?.size} flex flex-col gap-2`}/>
                                )
                            }
                            if(item?.hidden){
                                return
                            }
                            return(
                            <Fragment key={index}>
                                {
                                    (item?.simple ) &&
                                    <div key={index} className={`col-span-${item?.size} flex flex-col gap-2`}>
                                        <item.input item={item} />
                                    </div>
                                }
                            </Fragment>
                        )})
                    }

                        <div class="col-span-1 flex flex-col gap-1" style={{marginTop:'24px'}} >
                            <button onClick={onSearch} className='mt-[3px] rounded-md text-[12px] !w-full !min-h-[38px] bg-[#323a51] border-none text-white'>
                            Search
                            </button>
                        </div>

                        <div class="col-span-1 flex flex-col gap-1"  style={{marginTop:'24px'}}  >
                            <button onClick={onExportAll} className='mt-[3px] rounded-md text-[12px] !w-full !min-h-[38px] bg-[#323a51] border-none text-white'>
                            {
                                clientExport.isLoading && loadingRow===null && <Loading color="white" size="xs" />
                            }
                            Export All
                            </button>
                        </div>

                    </div>


                    <div className='grid-cols-2 !mb-5'>
                        <div className="col-span-2 max-[585px]:col-span-4 max-[445px]:col-span-2 justify-center flex flex-col gap-2">
                           
                            <div className="flex gap-2 items-center flex-wrap">
                                {
                                    chipData.map((item, index)=> (
                                        <div
                                        key={index} 
                                        onClick={()=>{item?.state ? item?.setState(false):item?.setState(true)}} 
                                        className={`${item.state ? "bg-[#28a745]" : "bg-[#ff4d4f]"} flex items-center h-[30px] rounded-full cursor-pointer justify-start`}
                                        >
                                            <div className="text-[24px] flex pl-[4px]">
                                                {
                                                    item.state ?
                                                    <i className='icon-cancel1 text-white'/>:
                                                    <i className='icon-plus1 text-white' />
                                                }
                                            </div>
                                            <div className="text-[10px] text-white font-semibold pl-2 pr-3">
                                                {item.label} Client
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </Fragment>
                       
                        {clientListing.isLoading ?
                            <LoaderCommon />:
                            <div className="table-wrpr">
                                <div className="table-section">
                                    <div className="row">
                                        <div className="col-md-12">                                   
                                            <table border={1} className="table prospect-table">
                                                <thead>
                                                <tr>
                                                    <th>Sl No</th>
                                                    <th className='min-w-[200px]'>Country</th>
                                                    <th className='min-w-[300px]'>No Of Clients</th>
                                                    <th>Action</th>    
                                                </tr>
                                                </thead>
                                                <tbody className="ui-sortable">
                                                    {result?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className="!text-[12px]">{index + 1}</td>
                                                            <td className="!text-[12px]">{item?.country}</td>
                                                            <td className="!text-[12px]">{item?.clients}</td>
                                                            <td className="!text-[12px]" width={100}>
                                                                <span
                                                                    onClick={() => {
                                                                        setLoadingRow(index); 
                                                                        onExport(item.countryID).finally(() => setLoadingRow(null)); 
                                                                    }}
                                                                >
                                                                    <i className="icon-export clientexport">
                                                                        {loadingRow === index && clientExport.isLoading && <Loading  size="xs" />}
                                                                    </i>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default ClientExport;


