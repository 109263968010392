import { Checkbox, Empty, message, Button as Buttons, Modal as Modals, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import authQueries from '../../queries/authQueries'
import $ from "jquery";
import { Button, Modal, Text, useModal } from '@nextui-org/react';
import { userStore } from '../../store/userStore';
import moment from 'moment';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Autocomplete, Fab, TextField } from '@mui/material';
import { UploadOutlined } from '@ant-design/icons';
import LoaderCommon from '../Common/LoaderCommon';
import { BASE_URL } from '../../apis/api';

const BdmDashboard = () => {

    const storeData = userStore()
    const [telecaller, setTellecaller] = useState(null)

    const navigate = useNavigate()

    const ApplicantListing = authQueries.useImportApplicantMutationList(
        (response) => {
        }
    );
    
    useEffect(()=> {
        ApplicantListing.mutateAsync()
    },[])


    

    const fileUploadImport = async (e)=>{

        console.log(refferal)

        if(!refferal){
            closeHandlerImport()
            message.warning("Please select source")
            return 
        }

        const data = new FormData();
        data.append('file', e)
        await uploadFile.mutateAsync(data, document)
    }

    const uploadFile = authQueries.useExcelUploadMutationCommon(
        (response) => {
            closeHandlerImport()
            setFailed(true)
            console.log(response)
            importFile.mutateAsync({file:response?.file?.filename, rid:refferal})
            if(response?.response?.data?.message === "File type not supported. Only xlsx file are supported.") {
                message.warning("File type not supported. Only xlsx file are supported.")
            }
        }
    );

    const [failed, setFailed] = useState(false)
    const [failedData, setFailedData] = useState([])

    const importFile = authQueries.useExcelImportMutationCreate(
        (response) => {
            if(response?.message === "Application imported successfully"){
                setRefferal(null)
                $("#fileControl").val('');
                setTimeout(() => {
                    ApplicantListing.mutateAsync()
                }, 1000);

                if(response?.successCount > 0){
                    message.success(`${response?.successCount} records imported successfully`);
                }else if(response?.successCount === 0){
                    message.warning(`No records imported successfully`);
                }
                
                if(response?.failedCount > 0){
                    setFailed(true)
                    setFailedData(response?.failed)
                }else if(response?.failedCount === 0){
                    setFailed(false)
                }
            }

        }
    );

    const closeFailed = () => {
        setFailed(false);
        setFailedData([])
    };


    const [checkID, setCheckID] = useState([]);

    const handleChange1 = (e) => {
        let selected = []
        const {checked} = e.target
        if(checked){
            ApplicantListing.data?.data?.items?.map(id => selected.push(id.id))
        }
        setCheckID(selected)
    };
  
    const handleChange2 = (e) => {
        const {checked, value} = e.target
        const selectID = parseInt(value)
        setCheckID([...checkID, selectID])
        if(!checked){
            setCheckID(checkID.filter((item) => item !== selectID));
        }
    };
  

    const children = (id) => {
        return (
            <Checkbox
                checked={checkID.find(number => number === id) ? true: false}  
                onChange={handleChange2} 
                value={id} 
            />
        );
    }

    const handlleSubmit =()=>{

        setVisible(false)
        setTellecaller(null)
        if(telecaller === null || telecaller === undefined || telecaller === "" || telecaller === 0 || telecaller === "0" || !telecaller){
            message.warning("Please select telecaller")
            return false
        }
        let dataValues = {
            id: checkID,
            telecaller: telecaller
        }
        setCheckID([])
        telecallerAssign.mutateAsync(dataValues)
    }

    const telecallerAssign = authQueries.useTelecallerAssignMutation(
        (response) => {
            message.success("Successfully assigned to telecaller")
            ApplicantListing.mutateAsync()
        }
    );

    const [visible, setVisible] = useState(false);

    const handler = () => {
        setVisible(true)
    }
  
    const closeHandler = () => {
        setVisible(false);
    };

    useEffect(()=>{
        userListing.mutateAsync({role:4})
        refferalList.mutateAsync({status:"ACTIVE", size: 100000})
    },[])


    const userListing = authQueries.useCounselorAssignList(
        (response) => {
        }   
    );
    
    const [visibleImport, setVisibleImport] = useState(false);
    const handlerImport = () => {
        setVisibleImport(true)
    }
  
    const closeHandlerImport = () => {
        setVisibleImport(false);
    };
    

    const [refferal, setRefferal] = useState(null)

    const refferalList = authQueries.useRefferalMutationList(
        (response) => {
        }
    );


    const navigateApplicant = (item) => {
        storeData.setApplication("")
        storeData.setApplicationAllData(
            {
                id: item?.id,
                applicant_name: item?.fname ? item?.fname : "",
                middlename: item?.mname ? item?.mname : "",
                lastname: item?.lname ? item?.lname : "",
                email: item?.email,
                mobile_no: item?.mobile,
                referral_id: item?.refferal?.id,
            }
        )
        navigate(`/clientadd`)
    }

    return (
        <div className="prg-coordinator">


            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Leads</li>
                    </ol>
                </nav>

                
            {
                ApplicantListing?.data?.data?.total > 0 && storeData?.user?.role === "BDM" &&
                    <div className="other-buttons">
                        <div className="upload-btn-wrapper-import" style={{float:'right'}}>
                            <button 
                                className="btn btn-md btn-dark btn-add-new-client btn-add-new-client2"
                                onClick={handlerImport}     
                            >
                                <i className="icon-cloud_upload"></i> Import Leads</button>
                        </div>
                    </div>
            }
            </div>


            {
                ApplicantListing?.isLoading &&
                    <div className="text-center">
                        <div className="spinner-border m-2" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
            }

            {
                ApplicantListing?.data?.data?.total > 0 &&
                <div id="accordion" className="add_clients_accordion_form">
                    <div className="card" id="appointments_wrpr">
                        <div className="card-header">
                            <a className="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>
                                {storeData?.user?.role == "TELECALLER" ? "Imported Lists" : "Imported Leads"}
                            </a>
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12 search-results-count-wrpr">
                                        {/* Search Results of {ApplicantListing?.data?.data?.total} */}
                                        
                                        {storeData?.user?.role === "BDM" && (
                                            <>
                                            Total Number of Imported Leads : {ApplicantListing?.data?.data?.total}
                                            </>
                                        )}
                                    </div>
                                    <div className="col-md-12">
                                        <table className="table collageprogram">
                                            <thead>
                                                <tr>
                                                    {
                                                        storeData?.user?.role === "BDM" &&
                                                        <th width="30">
                                                            <div style={{marginLeft:'10px'}}>
                                                                <Checkbox 
                                                                    checked={ApplicantListing?.data?.data?.total === checkID.length}
                                                                    indeterminate={ApplicantListing?.data?.data?.total !== checkID.length && checkID.length > 0}
                                                                    onChange={handleChange1} 
                                                                />
                                                            </div>
                                                        </th>
                                                    }
                                                    <th >Applicant Name</th>
                                                    <th >Imported Date</th>
                                                    <th >Mobile</th>
                                                    <th >Email</th>
                                                    <th>Source</th>
                                                    {
                                                        storeData?.user?.role === "TELECALLER" &&
                                                        <th>&nbsp;</th>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody className="ui-sortable">

                                            {
                                                ApplicantListing?.data?.data?.items?.map((item,index) => (
                                                <tr key={index} className="ui-sortable-handle">
                                                    {
                                                        storeData?.user?.role === "BDM" &&
                                                        <th>
                                                            <div style={{marginLeft:'10px'}}>
                                                                {children(item?.id)}
                                                            </div>
                                                        </th>
                                                    }
                                                    <td>
                                                        <button
                                                            style={{
                                                                background: 'transparent',
                                                                border: 'none',
                                                                color: '#1000FF',
                                                                outline: 'none',
                                                            }}
                                                        >{item?.fname} {item?.mname} {item?.lname}
                                                        </button>
                                                    </td>
                                                    <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                    <td>+{item?.mobile}</td>
                                                    <td>{item?.email}</td>
                                                    <td>{item?.refferal?.refferal}</td>
                                                    {
                                                        storeData?.user?.role === "TELECALLER" &&
                                                       <td>
                                                            <Buttons onClick={()=>{navigateApplicant(item)}} style={{background:'#FFC75E'}} type="primary" icon={<i className="icon-pencil"></i>} size={10} />
                                                        </td>
                                                    }
                                                </tr>
                                            ))}                                        
                                            </tbody>
                                        </table>
                                        {checkID.length > 0 &&
                                            <ul style={{justifyContent: 'rigtht',position: 'fixed', bottom: '50px', right: '50px'}} >
                                                <Button style={{outline:'none'}} onPress={handler}>
                                                    <i className="icon-user-add" style={{fontSize:'25px'}} />&nbsp;&nbsp;Assign to BDA
                                                </Button>
                                            </ul>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                ApplicantListing?.data?.data?.total === 0 &&
                <div style={{marginTop:'5%'}} className="text-center flex justify-center">
                        <Empty
                            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                            imageStyle={{ height: 80, display:'inline-block'}}
                            description={
                            <h6>
                                Sorry! No Leads found.
                            </h6>
                            }
                        >
                            <div className="upload-btn-wrapper-import">
                                {
                                    storeData?.user?.role === "BDM" &&
                                    <Buttons onClick={handlerImport} style={{fontFamily: "var(--primary-font)"}} type="primary">Import Leads</Buttons>
                                }
                    </div>
                        </Empty>
                </div>
            }



        <Modals
            title={<Text id="modal-title" style={{textAlign:'center'}} size={18}>Import File</Text>}
            centered
            okButtonProps={{ style:{display:'none'} }}
            cancelButtonProps={{ style:{display:'none'} }}
            open={visibleImport}            
            onOk={closeHandlerImport}
            onCancel={closeHandlerImport}
            width={'50%'}
        > 
            <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={refferalList?.data?.data?.items?.map((option) => option.refferal)}
                onChange={(event, newValue) => {
                    setRefferal(newValue)
                }}
                renderInput={(params) => (
                <TextField         
                    {...params}
                    label="Select / Add Source"
                    InputProps={{
                    ...params.InputProps,
                    type: 'search',
                    }}
                />
                )}
            />

            {
                refferal &&
                <div className="form-group" style={{marginTop:'20px'}}>
                    <div className="other-buttons">
                        <div className="upload-btn-wrapper-import">
                            <button className="btn btn-dark btn-add-new-client btn-add-new-client2 btn-block"><i className="icon-cloud_upload"></i> Import Leads</button>
                            <input onChange={(e)=>{fileUploadImport(e.target.files[0])}} type="file" name="myfile" id="fileControl" />
                        </div>
                    </div>
                </div>
            }


        </Modals>

        <Modal
            scroll
            width="600px"
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            open={visible}
            onClose={closeHandler}
        >
            <Modal.Header>
                <Text id="modal-title" size={18}>
                    Select BDA
                </Text>
            </Modal.Header>
            <Modal.Body>

            <div className="counsellor-card-wrpr">

                {userListing?.data?.data?.map((item, index) => (  
                    <div
                        key={index}
                        style={{width: "46%"}}
                        className={telecaller === item?.id ? "counsellor-card bluecard": "counsellor-card greencard"}
                        onClick={()=>{setTellecaller(item.id)}}
                    >
                        <div className="counsellor-photo-wrpr">
                            {/* <img src={require("../../assets/images/profile-avatar.jpg")} width="" height="" alt="" /> */}
                            <img src={item?.image? `${BASE_URL}/api/fileuploadcommon/${item?.image}` : require("../../assets/images/profile-avatar.jpg")} width="" height="" alt="" />

                        </div>
                        <div className="counsellor-name">
                            <h4>{item?.firstname} {item?.lastname}</h4>
                            {/* <div className="managing-clients"><span className="mc-count">{item.progress}</span> Clients</div> */}
                        </div>
                    </div>
                ))}
            </div>

            </Modal.Body>
            <Modal.Footer>
                <Button auto flat color="error" 
                    onPress={() => {
                        setVisible(false)
                        setTellecaller(null)
                        setCheckID([])
                    }}
                >
                    Close
                </Button>
                <Button auto onClick={handlleSubmit}>
                    Assign
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal
            scroll
            blur
            width="90%"
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            open={failed}
            closeButton={true}
            onClose={closeFailed}
        >
            <Modal.Header style={{justifyContent: 'space-around'}}>
                <Text b id="modal-title" size={18} style={{background: 'rgb(222,239,239)', color: '#5DC97A', padding: '5px', paddingLeft: '10px', paddingRight: '10px', borderRadius: '5px'}}>
                 Imported Data : {importFile?.data?.successCount}
                </Text>
                <Text b id="modal-title" size={18}>
                    Failed Data
                </Text>
                <Text b id="modal-title" size={18} style={{background: '#FEE8EB', color: '#FBB41B', padding: '5px', paddingLeft: '10px', paddingRight: '10px', borderRadius: '5px'}}>
                 Failed Data : {importFile?.data?.failedCount}
                </Text>
            </Modal.Header>
                <Modal.Body>
                    {importFile?.isLoading ?
                        <LoaderCommon />:
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Sl No</th>
                                    <th>Name</th>
                                    <th>Mobile</th>
                                    <th>Email</th>
                                    <th>Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    failedData?.map((item,index) => (
                                        <tr>
                                            <td>{item?.slno}</td>
                                            <td>{item?.name}</td>
                                            <td>{item?.mobile}</td>
                                            <td>{item?.email}</td>
                                            <td>{item?.reason}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    }
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>


        </div>

    )
}

export default BdmDashboard
