import { IconPlayerStop, IconPlayerPlay } from "@tabler/icons-react";
import { useRef, useState } from "react";
import { callLogUrl } from "../../Data/CallLogData";

const AudioPlayer = ({ callRefId }) => {

    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);
  
    let audioSource = `${callLogUrl}/WebAPI.xbc?streamcallwaveeasy()&callrefid=${callRefId}`
  
    const togglePlay = () => {
      if (audioRef.current) {
        if (isPlaying) {
          audioRef.current.pause();
        } else {
          audioRef.current.play().catch(error => {
            console.error('Error playing audio:', error);
            // Handle play errors (e.g., show user a message)
          });
        }
        setIsPlaying(!isPlaying);
      }
    };
  
    const handleTimeUpdate = () => {
      if (audioRef.current) {
        const progressPercent = 
          (audioRef.current.currentTime / audioRef.current.duration) * 100;
        setProgress(progressPercent);
      }
    };
  
    const handleLoadedMetadata = () => {
      if (audioRef.current) {
        setDuration(audioRef.current.duration);
      }
    };
  
    const formatTime = (timeInSeconds) => {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = Math.floor(timeInSeconds % 60);
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };
  
    return (
      <div className="audio-player flex items-center space-x-2">
        <audio
          ref={audioRef}
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={handleLoadedMetadata}
          onEnded={() => setIsPlaying(false)}
        >
          <source src={audioSource} type="audio/wav" />
          Your browser does not support the audio element.
        </audio>
        
        <button 
          onClick={togglePlay} 
          className="p-2 bg-blue-500 text-white rounded-full"
        >
          {isPlaying ? <IconPlayerStop size={20} /> : <IconPlayerPlay size={20} />}
        </button>
        
        <div className="flex-1">
          <div className="progress-bar w-full bg-gray-200 rounded-full h-2">
            <div 
              className="bg-blue-500 h-2 rounded-full" 
              style={{ width: `${progress}%` }}
            ></div>
          </div>
          <div className="flex justify-between text-xs text-gray-500 mt-1">
            <span>{formatTime(audioRef.current?.currentTime || 0)}</span>
            <span>{formatTime(duration)}</span>
          </div>
        </div>
      </div>
    );
};

export default AudioPlayer;

