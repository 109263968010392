 import React, { Fragment } from 'react'

const SelectSearch = ({item}) => {
  return (
    <Fragment>
        <div className="text-[12px] h-4 font-[500]">{item?.label}</div>
        <select
        value={item?.state}
        className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" 
        placeholder={item?.placeholder ? item?.placeholder : item?.label}
        onChange={(e)=> {item?.setState(e.target.value)}}
        >
            {
                !item?.hideBlankValue &&
                <option value="">{!item?.hideSelect && "Select"} {item?.label}</option>
            }
            {
                item?.value?.map((items, index)=> {
                    if(item?.array){
                        return(
                            <option key={index} value={items?.id}>{items?.[item?.name[0]]} {items?.[item?.name[1]]}</option>
                        )
                    }
                    else if(item?.visa){
                        return(
                            <option key={index} value={items?.visatype?.id}>{items?.visatype?.[item?.name]}</option>
                        )
                    }
                    else{
                        return(
                            <option key={index} value={items?.id}>{items?.[item.name]}</option>
                        )
                    }
                }
            )}
        </select>
    </Fragment>
    )
}

export default SelectSearch