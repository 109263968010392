import apiRequest from '../apis/api-request';
import { userStore } from '../store/userStore';

const accessToken = userStore.getState().user.accessToken

const authService = {
   
    register: async (oRegisterData) => {
        return apiRequest({
            method: "POST",
            url: "/api/users",
            data: oRegisterData,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },

    login: async (user) => {
        return apiRequest({
            method:"POST",
            url: "/api/users/login",
            data: user,
        });
    },

    userList: async (id) => {
        return apiRequest({
            method:"GET",
            url: `/api/users/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    forgot: async (user) => {
        return apiRequest({
            method:"PUT",
            url: "/api/users/forgotpassword",
            data: user,
        });
    },

    userFullList: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/users/full/list",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    userBranchList: async (data) => {

        let search = data?.search?data.search:''
        let size = data?.size?data.size:25
        let status = data?.status?data.status:0

        return apiRequest({
            method:"GET",
            url: `/api/users/branch/list?search=${search}&page=${data.page}&size=${size}&status=${status}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    userRoleList: async (data) => {

        let size = data?.size ? data.size : 25
        let page = data?.page ? data.page : 0
        
        return apiRequest({
            method:"POST",
            url: `/api/users/userlist/all?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    userFollowList: async (id) => {        
        return apiRequest({
            method:"GET",
            url: `/api/users/user/follow/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    userRoleView: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/users/view/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    userRoleEdit: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/users/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    userRoleDelete: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/users/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    counts: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/common/app/count",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    counselorDashboard: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/common/proslimit/couselor",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    counselorAppoinmentDashboard: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/common/cappointslist/couselor",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    adminUserList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/common/userlist/count",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    adminVisaList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/common/visatypelist/count",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    adminStatusList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/common/statuslist/count",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    adminOfficeList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/common/officelist/count",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    adminRolesList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/common/roleslist/count",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    adminClientList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/common/clientlist/count",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    feeTypeList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/common/feetype/list",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    college: async (data) => {
        const search = data?.search ? data?.search : ''
        const status = "ACTIVE"
        const page = data?.page ? data.page : 0
        const size = data?.size ? data.size : 25
        const campus = data?.campus ? "YES" : "NO"
        const country = data?.country ? data?.country : ""

        return apiRequest({
            method:"GET",
            url: `/api/colleges?search=${search}&status=${status}&page=${page}&size=${size}&campus=${campus}&country=${country}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    countryWisecollege: async (id) => {
        return apiRequest({
            method:"GET",
            url: `/api/colleges/country/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addCollege: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/colleges",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewCollege: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/colleges/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editCollege: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/colleges/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteCollege: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/colleges/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    qualification: async (data) => {
        const search = data?.search ? data?.search : ''
        const status = "ACTIVE"
        const page = data?.page ? data.page : 0
        const size = data?.size ? data.size : 25
        return apiRequest({
            method:"GET",
            url: `/api/qualifications?search=${search}&status=${status}&page=${page}&size=${size}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addQualification: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/qualifications",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewQualification: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/qualifications/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editQualification: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/qualifications/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteQualification: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/qualifications/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    country: async (data) => {

        let search = data?.search ? data.search : '';
        let status = data?.status ? data.status : '';
        let page = data?.page ? data.page : 0;
        let size = data?.size ? data.size : 25;
        let target = data?.target ? data.target : '';

        return apiRequest({
            method:"GET",
            url: `/api/countries?search=${search}&status=${status}&page=${page}&size=${size}&target=${target}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addCountry: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/countries",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewCountry: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/countries/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editCountry: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/countries/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteCountry: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/countries/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listScore: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/addscore/list",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addScore: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/addscore",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteScore: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/addscore/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editScore: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/addscore/editscore/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    counselorList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/addscore/counselorlist",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    noc: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/noc?search="+data.search+'&status='+data.status+'&page='+data.page+'&size='+data.size,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addNoc: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/noc",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editNoc: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/noc/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewNoc: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/noc/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteNoc: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/noc/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    office: async (data) => {

        let search = data?.search?data.search:'' 
        let status = data?.status?data.status:'ACTIVE' 
        let page = data?.page?data.page:0
        let size = data?.size?data.size:25
        let type = data?.type?data.type:''
        let magoffice = data?.magoffice?data.magoffice:''

        return apiRequest({
            method:"GET",
            url: `/api/offices?search=${search}&status=${status}&page=${page}&size=${size}&type=${type}&magoffice=${magoffice}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addOffice: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/offices",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewOffice: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/offices/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editOffice: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/offices/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteOffice: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/offices/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    program: async (data) => {

        let search = data?.search?data.search:'' 
        let status = data?.status?data.status:'ACTIVE' 
        let page = data?.page?data.page:0
        let size = data?.size?data.size:25
        let college = data?.college?data.college:''

        return apiRequest({
            method:"GET",
            url: `/api/programs?search=${search}&status=${status}&page=${page}&size=${size}&college=${college}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addProgram: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/programs",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewProgram: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/programs/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editProgram: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/programs/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteProgram: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/programs/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    visaType: async (data) => {

        let search = data?.search?data.search:'' 
        let status = data?.status?data.status:'ACTIVE' 
        let page = data?.page?data.page:0
        let size = data?.size?data.size:25

        return apiRequest({
            method:"GET",
            url: `/api/visatypes?search=${search}&status=${status}&page=${page}&size=${size}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addVisaType: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/visatypes",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewVisaType: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/visatypes/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editVisaType: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/visatypes/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteVisaType: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/visatypes/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    district: async (data) => {
        const search = data?.search ? data?.search : ""
        const status = data?.status ? data?.status : "ACTIVE"
        const size = data?.size ? data?.size : 25
        const page = data?.page ? data?.page : 0
        return apiRequest({
            method:"GET",
            url: `/api/districts?search=${search}&status=${status}&page=${page}&size=${size}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addDistrict: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/districts",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editDistrict: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/districts/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewDistrict: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/districts/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteDistrict: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/districts/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    notinterestedreason: async (data) => {
        const search = data?.search ? data?.search : ""
        const status = data?.status ? data?.status : "ACTIVE"
        const size = data?.size ? data?.size : 25
        const page = data?.page ? data?.page : 0
        return apiRequest({
            method:"GET",
            url: `/api/notinterestedreason?search=${search}&status=${status}&page=${page}&size=${size}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addNotinterestedreason: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/notinterestedreason`,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editNotinterestedreason: async (data) => {
        return apiRequest({
            method:"PUT",
            url: `/api/notinterestedreason/${data.id}`,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewNotinterestedreason: async (id) => {
        return apiRequest({
            method:"GET",
            url: `/api/notinterestedreason/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteNotinterestedreason: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: `/api/notinterestedreason/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    role: async (data) => {

        const search = data?.search ? data?.search : ""
        const status = data?.status ? data?.status : "ACTIVE"
        const size = data?.size ? data?.size : 25
        const page = data?.page ? data?.page : 0


        return apiRequest({
            method:"GET",
            url: `/api/roles?search=${search}&status=${status}&page=${page}&size=${size}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addRole: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/roles",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewRole: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/roles/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editRole: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/roles/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteRole: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/roles/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    intake: async (data) => {

        const search = data?.search?data.search:''
        const status = data?.status?data.status:''
        const page = data?.page?data.page:0
        const size = data?.size?data.size:25

        return apiRequest({
            method:"GET",
            url: `/api/intakes?search=${search}&status=${status}&page=${page}&size=${size}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    intakeVisa: async (id) => {
        return apiRequest({
            method:"GET",
            url: `/api/intakes/visa/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    addIntake: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/intakes",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewIntake: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/intakes/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editIntake: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/intakes/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteIntake: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/intakes/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    refferal: async (data) => {

        let search = data?.search?data.search:''
        let status = data?.status?data.status:''
        let page = data?.page?data.page:0
        let size = data?.size?data.size:25

        return apiRequest({
            method:"GET",
            url: `/api/refferals?search=${search}&status=${status}&page=${page}&size=${size}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addRefferal: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/refferals",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewRefferal: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/refferals/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editRefferal: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/refferals/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteRefferal: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/refferals/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    userDetails: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/userdetails?page="+data.page+'&size='+data.size,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addUserDetails: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/userdetails",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewUserDetails: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/userdetails/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editUserDetails: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/userdetails/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteUserDetails: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/userdetails/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    pnp: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/pnps?search="+data.search+'&status='+data.status+'&page='+data.page+'&size='+data.size,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addPnp: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/pnps",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewPnp: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/pnps/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editPnp: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/pnps/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deletePnp: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/pnps/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    university: async (data) => {

        let search = data?.search?data.search:''
        let status = data?.status?data.status:''
        let page = data?.page?data.page:0
        let size = data?.size?data.size:10

        return apiRequest({
            method:"GET",
            url: `/api/university?search=${search}&status=${status}&page=${page}&size=${size}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addUniversity: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/university",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewUniversity: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/university/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editUniversity: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/university/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteUniversity: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/university/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addApplicant: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/application/createapplication",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewApplicant: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/application/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deletApplicant: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/application/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editApplicant: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/application/"+ data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listApplicantBranchManager: async (data) => {

        let page = data?.page?data.page:data.page
        let size = data?.size?data?.size: 25

        return apiRequest({
            method:"POST",
            url: `/api/application/branchmanagerlist?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listApplicantRM: async (data) => {

        let page = data?.page?data.page:data.page
        let size = data?.size?data?.size: 25

        return apiRequest({
            method:"POST",
            url: `/api/application/rmlist?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listApplicantVisaManager: async (data) => {

        let page = data?.page?data.page:data.page
        let size = data?.size?data?.size: 25

        return apiRequest({
            method:"POST",
            url: `/api/application/visamanagerlist?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listApplicantAssingn: async (data) => {

        let page = data?.page?data.page:0
        let size = data?.size?data?.size: 25

        return apiRequest({
            method:"POST",
            url: `/api/application/assigned/list?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listApplicantAssingnProgram: async (data) => {

        let page = data?.page ? data.page : '' 
        let size = data?.size ? data.size : '' 
        
        return apiRequest({
            method:"POST",
            url: `/api/application/assigned/list/program?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listApplicantAssingnAdmission: async (data) => {

        let page = data?.page?data.page:0
        let size = data?.size?data?.size: 25

        return apiRequest({
            method:"POST",
            url: `/api/application/assigned/list/admission?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },
    
    listApplicantAssingnVisaStaff: async (data) => {

        let page = data?.page?data.page:0
        let size = data?.size?data?.size: 25

        return apiRequest({
            method:"POST",
            url: `/api/application/assigned/list/visastaff?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listApplicantAssingnDocumentStaff: async (data) => {

        let page = data?.page?data.page:0
        let size = data?.size?data?.size: 25

        return apiRequest({
            method:"POST",
            url: `/api/application/assigned/list/document?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listApplicantAssingnSop: async (data) => {

        let page = data?.page?data.page:0
        let size = data?.size?data?.size: 25

        return apiRequest({
            method:"POST",
            url: `/api/application/assigned/list/sop?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listTelecaller: async (data) => {

        let status = data?.status?data.status:''
        let page = data?.page?data.page:0
        let size = data?.size?data.size:25
        let prosStatus = data?.prosStatus?data?.prosStatus:''

        return apiRequest({
            method:"POST",
            url: `/api/application/assigned/list/telecaller?size=${size}&page=${page}&status=${status}&prosStatus=${prosStatus}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listAssociates: async (data) => {

        let status = data?.status?data.status:''
        let page = data?.page?data.page:0
        let size = data?.size?data.size:25
        let prosStatus = data?.prosStatus?data?.prosStatus:''

        return apiRequest({
            method:"POST",
            url: `/api/application/assigned/list/associates?size=${size}&page=${page}&status=${status}&prosStatus=${prosStatus}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    listEducational: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/educational/list/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewEducational: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/educational/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addEducational: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/educational/",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editEducational: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/educational/"+data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteEducational: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/educational/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addInform: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/additional_information_question",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listInform: async (data) => {

        let search = data?.search?data.search:''
        let status = data?.status?data.status:''
        let page = data?.page?data.page:0
        let size = data?.size?data.size:25

        return apiRequest({
            method:"GET",
            url: `/api/additional_information_question?search=${search}&status=${status}&page=${page}&size=${size}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewInform: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/additional_information_question/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editInform: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/additional_information_question/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteInform: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/additional_information_question/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listWorkExp: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/experience/list/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewWorkExp: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/experience/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addWorkExp: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/experience/",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editWorkExp: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/experience/"+data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteWorkExp: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/experience/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addOtherDetails: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/additional_information/",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editOtherDetails: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/additional_information/",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listOtherDetails: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/additional_information/list/"+ id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewOtherDetails: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/additional_information/${data.appid}/${data.qid}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addIntPro: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/intended_program/",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    listIntPro: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/intended_program/list/"+ id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    editIntPro: async (data) => {
        return apiRequest({
            method:"PUT",
            url: `/api/intended_program/${data.id}`,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteIntPro: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/intended_program/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    viewIntPro: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/intended_program/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    mailSendProgram: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/intended_program/mail/sending/",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    updateNotes: async (data) => {
        return apiRequest({
            method:"PUT",
            url: `/api/intended_program/notes/add/${data.intid}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    programConfirm: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/intended_program/statusupdation",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    dragOrder: async (data) => {
        return apiRequest({
            method:"PATCH",
            url: "/api/intended_program/drag/changeorder",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    addAcGap: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/academic_gap_history/",
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editAcGap: async (data) => {
        console.log('data', data)
        return apiRequest({
            method:"PUT",
            url: "/api/academic_gap_history/"+data.id,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listAcGap: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/academic_gap_history/list/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewAcGap: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/academic_gap_history/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteAcGap: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/academic_gap_history/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    addTravelHistory: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/travelhistory/",
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    editTravelHistory: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/travelhistory/"+data.id,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listTravelHistory: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/travelhistory/list/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewTravelHistory: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/travelhistory/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteTravelHistory: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/travelhistory/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addServiceSought: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/services_sought/",
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    editServiceSought: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/services_sought/"+data.id,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listServiceSought: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/services_sought/list/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewServiceSought: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/services_sought/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteServiceSought: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/services_sought/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listAppointment: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/appointment_types?search="+data.search+'&status='+data.status+'&page='+data.page+'&size='+data.size,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addAppointment: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/appointment_types",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewAppointment: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/appointment_types/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editAppointment: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/appointment_types/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteAppointment: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/appointment_types/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listDoc: async (data) => {

        let search = data?.search?data.search:''
        let status = data?.status?data.status:''
        let page = data?.page?data.page:0
        let size = data?.size?data.size:25

        return apiRequest({
            method:"GET",
            url: `/api/documents?${search}&status=${status}&page=${page}&size=${size}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addDoc: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/documents",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewDoc: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/documents/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editDoc: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/documents/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteDoc: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/documents/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addAppointClient: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/followups/",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    listAppointClient: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/followups/list/"+ id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listFollowupHistory: async (id) => {
        return apiRequest({
            method:"GET",
            url: `/api/followups/history/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    editAppointClient: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/followups/"+data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteAppointClient: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/followups/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    viewAppointClient: async (id) => {
        return apiRequest({
            method:"get",
            url: "/api/followups/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    followupCommentAdd: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/followups/comment",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    followupConfirm: async (data) => {
        return apiRequest({
            method:"PATCH",
            url: `/api/followups/confirm`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },
    
    addOverseas: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/overseas_education_history/",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    listOverseas: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/overseas_education_history/list/"+ id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    editOverseas: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/overseas_education_history/"+data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteOverseas: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/overseas_education_history/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    viewOverseas: async (id) => {
        return apiRequest({
            method:"get",
            url: "/api/overseas_education_history/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    addAssign: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/assign_to_counsellor/",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    listAssign: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/assign_to_counsellor/list/"+ id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewRoleAssign: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/assign_to_counsellor/role/"+ data.rid+"/"+data.aid,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editAssign: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/assign_to_counsellor/"+data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteAssign: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/assign_to_counsellor/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewAssign: async (id) => {
        return apiRequest({
            method:"get",
            url: "/api/assign_to_counsellor/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listCounselorAssign: async (data) => {
        const role = data?.role ? data.role : "";
        const search = data?.search ? data.search : "";
        const office = data?.office ? data.office : "";
        const visa = data?.visa ? data.visa : ""

        return apiRequest({
            method:"GET",
            url: `/api/assign_to_counsellor/staff/list/office/${role}?search=${search}&office=${office}&visa=${visa}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    telecallerAssign: async (data) => {
        return apiRequest({
            method:"PATCH",
            url: `/api/assign_to_counsellor/telecaller/assign`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listProspect: async (data) => {
        let search = data.search ? data.search : "";
        let status = data.status ? data.status : "";
        let page = data.page ? data.page : 0;
        let size = data.size ? data.size : 25;
        return apiRequest({
            method:"GET",
            url: `/api/status_of_prospects?search=${search}&status=${status}&page=${page}&size=${size}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addProspect: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/status_of_prospects",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewProspect: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/status_of_prospects/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editProspect: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/status_of_prospects/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteProspect: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/status_of_prospects/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    roleWiseStatusOfProspect: async (data) => {

        let role = data?.role ? data?.role : ''
        let visa = data?.visa ? data?.visa : ''
        let showstatus = data?.showstatus ? data?.showstatus : ""

        return apiRequest({
            method:"GET",
            url: `/api/status_of_prospects/status/roles?role=${role}&visa=${visa}&showstatus=${showstatus}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listDuration: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/program_duration?search="+data.search+'&status='+data.status+'&page='+data.page+'&size='+data.size,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addDuration: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/program_duration",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewDuration: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/program_duration/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editDuration: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/program_duration/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteDuration: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/program_duration/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listFollowDuration: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/followup?search="+data.search+'&status='+data.status+'&page='+data.page+'&size='+data.size,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addFollowDuration: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/followup",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewFollowDuration: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/followup/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editFollowDuration: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/followup/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteFollowDuration: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/followup/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },    
    
    listStatusPro: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/applicant_prospects_status/list/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewStatusPro: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/applicant_prospects_status/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addStatusPro: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/applicant_prospects_status/",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editStatusPro: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/applicant_prospects_status/"+data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteStatusPro: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/applicant_prospects_status/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addDocument: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/documents/",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editDocument: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/documents/"+data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listDocument: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/documents/list/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewDocument: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/documents/"+1,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listPayment: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/payments/list/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewPayment: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/payments/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addPayment: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/payments/",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editPayment: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/payments/"+data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deletePayment: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/payments/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addFee: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/fees/",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editFee: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/fees/"+data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewFee: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/fees/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listComment: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/common/list/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addEnglishPro: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/english_proficiency_test/",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listEnglishPro: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/english_proficiency_test/list/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteEnglishPro: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/english_proficiency_test/"+id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editEnglishPro: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/english_proficiency_test/"+data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },
    
    progressChange: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/application/progress/"+ data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    emailValidationApplicant: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/application/emailValid/",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    mobileValidationApplicant: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/application/mobileValid/",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    signedFileUrl: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/fileuploadcommon/signed/download",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    fileUploadCommon: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/fileuploadcommon",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type":  "multipart/form-data"
            }
        });
    },

    fileUploadCommonApplicant: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/fileuploadcommon/applicant",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type":  "multipart/form-data"
            }
        });
    },

    pdfUploadCommon: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/fileuploadcommon/pdf",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type":  "multipart/form-data"
            }
        });
    },

    pdfMergeCommon: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/fileuploadcommon/merge/pdf",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken            }
        });
    },

    pdfDeleteCommon: async (file) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/fileuploadcommon/deletefile/pdf/"+ file,
            headers: {
                Authorization: "Bearer " + accessToken            }
        });
    },


    excelUploadCommon: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/fileuploadcommon/uploadexcel",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type":  "multipart/form-data"
            }
        });
    },

    applicantFileUploadAdd: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/fileupload",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    applicantFileUploadEdit: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/fileupload/"+data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    applicantFileUploadList: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/fileupload/list/"+id,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    }, 
    
    applicantFileUploadView: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/fileupload/view/"+data.id+"/"+data.aid,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    applicantFileUploadDelete: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/fileupload/"+id,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    currencyList: async (data) => {

        let page = data?.page?data.page:''
        let size = data?.size?data?.size: ''

        return apiRequest({
            method:"GET",
            url: `/api/currency?size=${size}&page=${page}`,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    admissionStaffListing: async (data) => {

        let page = data?.page?data.page:''
        let size = data?.size?data?.size: ''

        return apiRequest({
            method:"GET",
            url: `/api/appstaffstatus?size=${size}&page=${page}`,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    admissionProgramWiseListing: async (id) => {

        return apiRequest({
            method:"GET",
            url: `/api/admission/program/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    admissionRegisterCreate: async (data) => {

        return apiRequest({
            method:"POST",
            url: `/api/admission`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    admissionFeeTypeCreate: async (data) => {

        return apiRequest({
            method:"POST",
            url: `/api/admission/program/admission/`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    admissionFeeTypeUpdate: async (data) => {
        return apiRequest({
            method:"PUT",
            url: `/api/admission/program/admission`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    admissionRegisterUpdate: async (data) => {
        return apiRequest({
            method:"PUT",
            url: `/api/admission/${data.id}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    feeCoordinatorListing: async (data) => {
        let page = data?.page?data.page: 0
        let size = data?.size?data?.size: 25
        return apiRequest({
            method:"POST",
            url: `/api/application/assigned/list/fee?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    feeCoordinatorModalListing: async (id) => {
        return apiRequest({
            method:"GET",
            url: `/api/feecoordinator/admission/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    feeCoordinatorModalPayment: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/feecoordinator`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    feeCoordinatorModalPaymentList: async(id) => {
        return apiRequest({
            method:"GET",
            url: `/api/feecoordinator/admission/applicant/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    visaDataCreate: async (data) => {

        return apiRequest({
            method:"POST",
            url: `/api/visadatas`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    applicantViewAllProfile: async (id) => {
        return apiRequest({
            method:"GET",
            url: `/api/application/view/applicant/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    markRegistration: async (data) => {
        return apiRequest({
            method:"PUT",
            url: `/api/application/markregistration/update/${data.id}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    importFile: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/impex/${data.file}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    importList: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/impex/`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    importDelete: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: `/api/impex/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    counselorReport: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report?from="+data.from+'&to='+data.to,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    clientList: async (data) => {

        let page = data?.page?data.page:data.page
        let size = data?.size?data?.size: 25
        let search = data?.name?data.name:'' 
        let mobile = data?.mobile?data.mobile:'' 
        let visa = data?.visa?data.visa:'' 
        let email = data?.email?data.email:'' 
        let intake = data?.intake?data.intake:''
        let status = data?.status?data.status:''
        let counselor = data?.counselor?data.counselor:''

        return apiRequest({
            method:"GET",
            url: `/api/application/all/clientlist/?page=${page}&size=${size}&search=${search}&mobile=${mobile}&email=${email}&visa=${visa}&intake=${intake}&pros_status=${status}&counselor=${counselor}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    alllistCampus: async (data) => {
        const search = data.search ? data.search : ""
        const status = data.status ? data.status : ""
        const page = data.page ? data.page : ""
        const size = data.size ? data.size : ""
        return apiRequest({
            method:"GET",
            url: `/api/campus/?search=${search}&status=${status}&page=${page}&size=${size}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listCampus: async (data) => {
        const search = data?.search ? data.search : ""
        const status = data?.status ? data.status : ""
        const page = data?.page ? data.page : ""
        const size = data?.size ? data.size : ""
        return apiRequest({
            method:"GET",
            url: `/api/campus/list/${data.id}?search=${search}&status=${status}&page=${page}&size=${size}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addCampus: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/campus/create",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewCampus: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/campus/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editCampus: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/campus/update/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteCampus: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/campus/delete/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    counselorDashboardCount: async (data) => {
        const typeStatus = data?.type === "YES" ? "YES" : "NO";
        return apiRequest({
            method:"GET",
            url: `/api/dashboard/counselor/counts?type=${typeStatus}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    counselorDashboardFeePaid: async (data) => {
        const assign = data?.assign === "YES" ? "YES" : "NO"
        return apiRequest({
            method:"GET",
            url: `/api/dashboard/counselor/feepaid?assign=${assign}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    associatesDashboardFeePaid: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/dashboard/associates/feepaid`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },
   
    counselorDashboardVisa: async () => {
        return apiRequest({
            method:"GET",
            url: `/api/dashboard/counselor/visa`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    counselorDashboardReg: async () => {
        return apiRequest({
            method:"GET",
            url: `/api/dashboard/counselor/register`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    counselorDashboardFollowup: async (data) => {
        const page = data?.page ? data.page : ""
        const size = data?.size ? data.size : 25    
        return apiRequest({
            method:"POST",
            url: `/api/followupdashboard/common/today?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    counselorDashboardPending: async (data) => {
        const page = data?.page ? data.page : ""
        const size = data?.size ? data.size : 25
        return apiRequest({
            method:"GET",
            url: `/api/followupdashboard/common/leadstatus?status=${data?.status}&page=${page}&size=${size}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    counselorDashboardAutoFollowup: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/followupdashboard/common/autofollowup`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    counselorDashboardAllPending: async (data) => {
        const page = data?.page ? data.page : ""
        const size = data?.size ? data.size : 25
        return apiRequest({
            method:"POST",
            url: `/api/followupdashboard/common/pending?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    counselorDashboardTotal: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/dashboard/counselor/totalassigned`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    counselorDashboardActive: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/dashboard/counselor/active`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    branchManagerDashboardCount: async () => {
        return apiRequest({
            method:"GET",
            url: `/api/dashboard/branchmanager/counts`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    regionalManagerCount: async (id) => {
        return apiRequest({
            method:"GET",
            url: `/api/dashboard/rmmanager/counts/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    seniorProgramManagerCount: async () => {
        return apiRequest({
            method:"GET",
            url: `/api/dashboard/seniorprogrammanager/counts`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    telecallerDashboardCount: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/dashboard/telecaller/dashboard/count` ,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    uniquestatusReport: async (data) => {
        return apiRequest({
        method:"GET",
        url: "/api/report/uniquestatus?page="+data.page+'&size='+data.size,
        headers: {
                     Authorization: "Bearer " + accessToken
                }
        });
    },


    targetOfficeList: async (type) => {
        return apiRequest({
            method:"GET",
            url: `/api/target/office?type=${type}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    targetView: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/target/${data.user}/${data.country}?year=${data.year}&type=${data.type}` ,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    targetAdd: async (data) => {
        return apiRequest({
            method:"POST",
            data: data,
            url: `/api/target` ,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    targetCountView: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/target/total/target/${data.id}?year=${data?.year}` ,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    telecallerDashboard: async (id) => {
        return apiRequest({
            method:"GET",
            url: `/api/telecallerdashboard/counts` ,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    counselorDashboardTarget: async (id) => {
        return apiRequest({
            method:"GET",
            url: `/api/dashboard/counselor/target/graph` ,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    admissionFeePaidDelete: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: `/api/admission/program/delete/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listAppStaffStatus: async (data) => {
        const search = data.search ? data.search : ""
        const status = data.status ? data.status : ""
        const page = data.page ? data.page : ""
        const size = data.size ? data.size : ""
        return apiRequest({
            method:"GET",
            url: `/api/appstaffstatus?search=${search}&status=${status}&page=${page}&size=${size}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addAppStaffStatus: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/appstaffstatus`,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editAppStaffStatus: async (data) => {
        return apiRequest({
            method:"PUT",
            url: `/api/appstaffstatus/${data.id}`,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewAppStaffStatus: async (id) => {
        return apiRequest({
            method:"GET",
            url: `/api/appstaffstatus/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteAppStaffStatus: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: `/api/appstaffstatus/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listFeePaidType: async (data) => {

        let search = data?.search ? data.search : '';
        let status = data?.status ? data.status : '';
        let page = data?.page ? data.page : 0;
        let size = data?.size ? data.size : 25;
        let target = data?.target ? data.target : '';

        return apiRequest({
            method:"GET",
            url: `/api/feepaidtypes?search=${search}&status=${status}&page=${page}&size=${size}&target=${target}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addFeePaidType: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/feepaidtypes",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewFeePaidType: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/feepaidtypes/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editFeePaidType: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/feepaidtypes/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteFeePaidType: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/feepaidtypes/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listLeadType: async (data) => {

        let search = data?.search ? data.search : '';
        let status = data?.status ? data.status : '';
        let page = data?.page ? data.page : 0;
        let size = data?.size ? data.size : 25;
        let target = data?.target ? data.target : '';

        return apiRequest({
            method:"GET",
            url: `/api/leadtypes?search=${search}&status=${status}&page=${page}&size=${size}&target=${target}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    addLeadType: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/leadtypes",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewLeadType: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/leadtypes/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editLeadType: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/leadtypes/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteLeadType: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/leadtypes/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    counselorDashboardIELTSCount: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/dashboard/counselor/ielts/count`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    counselorDashboardIELTSPendingList: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/dashboard/counselor/ielts/pending`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    counselorDashboardTOTCount: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/dashboard/counselor/tot/count`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    counselorDashboardTOTPendingList: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/dashboard/counselor/tot/pending`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },



    employeeReport: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/employee?name="+data.name+"&office="+data.office,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    counselorRegistration: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/counselor",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    counselorCountryRegistration: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/counselorcountry",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    associatesDashboardCount: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/dashboard/associates/dashboard/count?fyear=${data.fyear}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    ceoDashboardCount: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/dashboard/ceo/dashboard/count",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    ceoDashboardTotalCount: async () => {
        return apiRequest({
            method:"GET",
            url: "/api/dashboard/ceo/dashboard/totalcount",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    ceoDashboardCFYCount: async (data) => {
        const fy = data ? data : ""
        return apiRequest({
            method:"GET",
            url: `/api/dashboard/ceo/dashboard/cfycount?fy=${fy}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    ceoDashboardCountryCount: async () => {
        return apiRequest({
            method:"GET",
            url: "/api/dashboard/ceo/dashboard/country",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    managerDashboardCount: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/dashboard/manager/dashboard/count",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    leaderBoardCount: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/dashboard/counselor/leaderboard/count",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    ceoClientList: async (data) => {
        let page = data?.page?data.page: 0
        let size = data?.size?data?.size: 25
        return apiRequest({
            method:"POST",
            url: `/api/application/ceo/list?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listMarkRegisterPending: async (data) => {

        let page = data?.page?data.page:data.page
        let size = data?.size?data?.size: 25

        return apiRequest({
            method:"POST",
            url: `/api/application/markregister/pending?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    countrywise: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/countrywise?from="+data.from+'&to='+data.to+'&userId='+data.userId+'&role='+data.role+'&officeId='+data.officeId+'&vids='+data.Vids,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    visaStaffDashboardCount: async () => {
        return apiRequest({
            method:"GET",
            url: "/api/dashboard/visa/counts",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    uniquecountrywise: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/uniquecountrywise?from="+data.from+'&to='+data.to+'&role='+data.role+'&officeId='+data.officeId+'&vids='+data.Vids+'&userId='+data.userId,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },
     
    
    feeCoordinatorDashboardCount: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/dashboard/feecoordinator/counts",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    programDashboardCount: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/dashboard/program/counts",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    admissionStaffDashboardCount: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/dashboard/admission/counts",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    uniqueApplicantlist: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/report/applicants?type=${data.type}&visa=${data.visa}&from=${data.from}&to=${data.to}&country=${data.country+'&role='+data.role+'&officeId='+data.officeId+'&vids='+data.Vids}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    notInterestReportCEO: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/ceo/report/notinterested`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    notQualifiedReportCEO: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/ceo/report/notqualified`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    feePaidCEOReport: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/ceo/report/feepaid`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    visaApprovedCEOReport: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/ceo/report/visaapproved`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },
    
    countryApplicantList: async (data) => {
        return apiRequest({
            method:"GET", 
            url: `/api/report/countryapplicants?type=${data.type}&visa=${data.visa}&country=${data.country}&from=${data.from}&to=${data.to}&userId=${data.userId}&role=${data.role}&officeId=${data.officeId}&vids=${data.Vids}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },
    
    notificiationList: async (data) => {
        const notify = data.type?data.type:''
        const page = data.page?data.page:0
        const size = data.size?data.size:25
        const important = data.important === "YES" ? "YES" : "NO"
        return apiRequest({
            method:"GET",
            url: `/api/notification/list?type=${notify}&page=${page}&size=${size}&important=${important}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    notificiationRead: async (id) => {
        return apiRequest({
            method:"POST",
            url: `/api/notification/read/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    notificiationReadAll: async (id) => {
        return apiRequest({
            method:"POST",
            url: `/api/notification/readAll`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },
    
    createVisaData: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/visadatas/`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listVisaData: async (id) => {
        return apiRequest({
            method:"GET",
            url: `/api/visadatas/list/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    viewVisaData: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/visadatas/view`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    ceoDashboardReference: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/dashboard/ceo/dashboard/reference`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    ceoDashboardBranch: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/dashboard/ceo/dashboard/branch`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    counselorWiseListing: async (data) => {
        const page = data?.page?data.page:0
        const size = data?.size?data.size:25
        const role = data?.role?data.role:""
        const id = data?.id?data.id:""
        const status = data?.status?data.status:""
        const visa = data?.visa?data.visa:""
        const reg = data?.reg?data.reg:""
        return apiRequest({
            method:"GET",
            url: `/api/application/assigned/list/admin/${id}/${role}?page=${page}&size=${size}&status=${status}&visa=${visa}&reg=${reg}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    bulkAssign: async (data) => {
        return apiRequest({
            method:"PATCH",
            url: `/api/assign_to_counsellor/bulk/assign`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    searchApplicant: async (data) => {

        const page = data?.page?data.page:0
        const size = data?.size?data.size:25

        return apiRequest({
            method:"POST",
            url: `/api/application/search/list/all?size=${size}&page=${page}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    branchwiseReport: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/branchwise?from="+data.from+'&to='+data.to,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    branchTargetReport: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/btarget?from="+data.from+'&to='+data.to,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },
    countryTargetReport: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/ctarget?from="+data.from+'&to='+data.to,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    feeStatusReport: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/feecount?cid="+data.cid,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    referencewiseReport: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/reference?from="+data.from+'&to='+data.to,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    executiveApplicant: async (data) => {

        const page = data?.page? data.page:0
        const size = data?.size? data.size:25

        return apiRequest({
            method:"POST",
            url: `/api/application/assigned/list/executive?&size=${size}&page=${page}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    exportApplicant: async (id) => {
        return apiRequest({
            method:"GET",
            url: `/api/impex/exportApplicant?status=${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },
    
    applicationLoanList: async (data) => {
        
        const page = data?.page ? data.page : 0
        const size = data?.size ? data.size : 25

        return apiRequest({
            method:"POST",
            url: `/api/application/assigned/executive/list?size=${size}&page=${page}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    applicationLoanConfirm: async (data) => {
        return apiRequest({
            method:"PATCH",
            url: `/api/application/assigned/executive/confirm`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },
    
    markRegCounts: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/dashboard/markreg/counts`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    gicAssign: async (id) => {
        return apiRequest({
            method:"PATCH",
            url: `/api/application/gicassign/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    programDocCreate: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/intended_program/program/document`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    programDocList: async (id) => {
        return apiRequest({
            method:"GET",
            url: `/api/intended_program/program/document/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    }, 
    
    programDocDelete: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: `/api/intended_program/program/document/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    exportCeoAllApplicant: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/impex/export/ceoexcel`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    dailyReportFollowup: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/followups/daily/report`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    dailyReportCEOFollowup: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/followups/daily/ceoreport`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },
    
    counselorAssociates: async (data) => {

        const page = data?.page? data.page:0
        const size = data?.size? data.size:25
        const search = data?.search? data.search:''
        const user = data?.user ? "YES": "NO"

        return apiRequest({
            method:"GET",
            url: `/api/associates?search=${search}&size=${size}&page=${page}&user=${user}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },    
    
    addCounselorAssociates: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/associates`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteCounselorAssociates: async (data) => {
        return apiRequest({
            method:"DELETE",
            url: `/api/associates`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    officeCounselorAssociates: async (data) => {

        const role = data?.role ? data.role : "";
        const search = data?.search ? data.search : "";
        const ref = data?.ref ? data.ref : "";

        return apiRequest({
            method:"GET",
            url: `/api/associates/office/3?search=${search}&ref=${ref}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },
    
    officeWiseCounselorList: async (data) => {
        const role = data?.role ? data.role : "";
        const office = data?.office ? data.office : "";
        return apiRequest({
            method:"GET",
            url: `/api/users/counselor/list/office?role=${role}&office=${office}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },
    
    officeWiseOnlyCounselorList: async (id) => {
        const office = id ? id : "";
        return apiRequest({
            method:"GET",
            url: `/api/users/office/list/users/?office=${office}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },    
    
    roleWiseOnlyCounselorList: async (id) => {
        const role = id ? id : "";
        return apiRequest({
            method:"GET",
            url: `/api/users/role/list/users/?role=${role}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },    
    
    englishProfiencyStatus: async (data) => {
        return apiRequest({
            method:"PATCH",
            url: `/api/english_proficiency_test/status/`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },
        
    allNotInterestOrQualifiedList: async (data) => {
        const page = data?.page ? data?.page : 0
        const size = data?.size ? data?.size : 25
        const id = data?.id ? data?.id : 2
        return apiRequest({
            method:"POST",
            url: `/api/application/all/client/notclientlist?&page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },
        
    allLongAndHoldClientsList: async (data) => {
        const page = data?.page ? data?.page : 0
        const size = data?.size ? data?.size : 25
        return apiRequest({
            method:"POST",
            url: `/api/application/all/client/longorholdclientlist?&page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    reAssignCounselor: async (id) => {
        return apiRequest({
            method:"PATCH",
            url: `/api/assign_to_counsellor/reassign/counselor/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },    
    
    captureApplicantTelecaller: async (id) => {
        return apiRequest({
            method:"PATCH",
            url: `/api/assign_to_counsellor/capture/telecaller/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },    
    
    applicantProgramPublicList: async (token) => {
        return apiRequest({
            method:"GET",
            url: `/api/intended_program/public/program/applicants/list`,
            headers: {
                Authorization: token
            }
        });
    },

    applicantProgramPublicUpdate: async (data) => {
        return apiRequest({
            method:"PATCH",
            url: `/api/intended_program/public/program/applicants/update`,
            data: data?.data,
            headers: {
                Authorization: data?.token
            }
        });
    },

    programSuggestionList: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/intended_program/program/suggestion`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    topPerformList: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/ceo/report/topperformer`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    associatesReport: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/ceo/report/associates_report`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    associatesReportListing: async (data) => {
        const page = data?.page ? data?.page : 0
        const size = data?.size ? data?.size : 25
        return apiRequest({
            method:"POST",
            url: `/api/ceo/report/associates_report/list?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    finishedClientsList: async (data) => {
        const page = data?.page ? data?.page : 0
        const size = data?.size ? data?.size : 25
        return apiRequest({
            method:"POST",
            url: `/api/application/all/finished/clients?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    finishedClientsVisaTypeList: async (data) => {
        const page = data?.page ? data?.page : 0
        const size = data?.size ? data?.size : 25
        return apiRequest({
            method:"POST",
            url: `/api/application/all/finished/visaclients?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    finishedClientsOfficeList: async (data) => {
        const page = data?.page ? data?.page : 0
        const size = data?.size ? data?.size : 25
        return apiRequest({
            method:"POST",
            url: `/api/application/all/finished/officeclients?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    visaWiseStatusListing: async (id) => {
        return apiRequest({
            method:"GET",
            url: `/api/status_of_prospects/status/visawise/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    roleWiseStatusListing: async (id) => {
        return apiRequest({
            method:"GET",
            url: `/api/status_of_prospects/status/rolewise/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    visaWiseStatusCreate: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/status_of_prospects/status/visa`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },    
    
    roleWiseStatusCreate: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/status_of_prospects/status/role`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    associateTileList: async (data) => {
        const page = data?.page ? data?.page : 0
        const size = data?.size ? data?.size : 25
        return apiRequest({
            method:"POST",
            url: `/api/dashboard/associates/dashboard/list?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    roleWiseUserList: async (role) => {
        return apiRequest({
            method:"GET",
            url: `/api/users/rolewise/list/users/${role}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    followUpTodayOfficeWise: async (data) => {
        const page = data?.page ? data?.page : 0
        const size = data?.size ? data?.size : 25
        return apiRequest({
            method:"POST",
            url: `/api/followupdashboard/bm/today?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    followUpPendingOfficeWise: async (data) => {
        const page = data?.page ? data?.page : 0
        const size = data?.size ? data?.size : 25
        return apiRequest({
            method:"POST",
            url: `/api/followupdashboard/bm/pending?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    followUpTodaySPM: async (data) => {
        const page = data?.page ? data?.page : 0
        const size = data?.size ? data?.size : 25
        return apiRequest({
            method:"POST",
            url: `/api/followupdashboard/spm/today?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    followUpPendingSPM: async (data) => {
        const page = data?.page ? data?.page : 0
        const size = data?.size ? data?.size : 25
        return apiRequest({
            method:"POST",
            url: `/api/followupdashboard/spm/pending?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    advancedSearchMasters: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/common/advanced/search`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    applicantFormMaster: async (data) => {
        const visa = data?.visa ? data?.visa : ""
        const office = data?.office ? data?.office : ""
        const refferal = data?.refferal ? data?.refferal : ""
        return apiRequest({
            method:"GET",
            url: `/api/common/applicationform/masters?visa=${visa}&office=${office}&refferal=${refferal}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    changePassword: async (data) => {
        return apiRequest({
            method:"PUT",
            url: `/api/users/changepassword`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    oldDocumentList: async (id) => {
        return apiRequest({
            method:"GET",
            url: `/api/fileuploadcommon/oldfiles/download/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    followupTodayCountBM: async () => {
        return apiRequest({
            method:"GET",
            url: `/api/followupdashboard/bm/today/count`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    followupPendingCountBM: async () => {
        return apiRequest({
            method:"GET",
            url: `/api/followupdashboard/bm/pending/count`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    bmFollowupDropDown: async () => {
        return apiRequest({
            method:"GET",
            url: `/api/common/branchmanager/followupdrop`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    spmFollowupDropDown: async () => {
        return apiRequest({
            method:"GET",
            url: `/api/common/spm/followupdrop`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    counselorapplicants: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/report/counselorapplicants?type=${data.type}&uid=${data.uid}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    seniorManagerListingMaster: async (data) => {
        const search = data?.search ? data?.search : ""
        const size = data?.size ? data?.size : 25
        const page = data?.page ? data?.page : 0
        return apiRequest({
            method:"GET",
            url: `/api/seniorprogram?search=${search}&size=${size}&page=${page}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    seniorManagerAddMaster: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/seniorprogram`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    seniorManagerRemoveMaster: async (data) => {
        return apiRequest({
            method:"DELETE",
            url: `/api/seniorprogram`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    seniorManagerUsersList: async (data) => {
        const search = data?.search ? data?.search : ""
        const size = data?.size ? data?.size : 25
        const page = data?.page ? data?.page : 0
        return apiRequest({
            method:"GET",
            url: `/api/seniorprogram/list?search=${search}&size=${size}&page=${page}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    fairApplicationCreate: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/fair`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    fairApplicationList: async (data) => {
        const page = data?.page ? data?.page : 0
        const size = data?.size ? data?.size : 25
        return apiRequest({
            method:"GET",
            url: `/api/fair?page=${page}&size=${size}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    fairApplicationEdit: async (data) => {
        return apiRequest({
            method:"PUT",
            url: `/api/fair`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listSPMMyclientList: async (data) => {

        let page = data?.page?data.page:0
        let size = data?.size?data?.size: 25

        return apiRequest({
            method:"POST",
            url: `/api/application/list/seniorpgmmanager?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listAssignedSPCMyclientList: async (data) => {

        let page = data?.page?data.page:0
        let size = data?.size?data?.size: 25

        return apiRequest({
            method:"POST",
            url: `/api/application/assigned/list/seniorpgmcoordinator?page=${page}&size=${size}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    exportFairApplication: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/impex/export/fairstaff`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    programSuggestionMaster: async () => {
        return apiRequest({
            method:"GET",
            url: `/api/common/program/suggestion`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    sopStaffDashboardCount: async () => {
        return apiRequest({
            method:"GET",
            url: `/api/dashboard/sopstaff/counts`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    userdetailsAssigned: async (id) => {
        return apiRequest({
            method:"GET",
            url: `/api/assign_to_counsellor/assigned/applicants/userlist/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    counselorCountryApplicantlist: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/report/counselorcountrywiseapplicants?country=${data.country}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    programPendingList: async (data) => {
        const page = data?.page ? data?.page : 0
        const size = data?.size ? data?.size : 25
        return apiRequest({
            method:"GET",
            url: `/api/intended_program/program/pending/list?page=${page}&size=${size}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    changeLeadStatus: async (data) => {
        return apiRequest({
            method:"PATCH",
            url: `/api/application/leadstatus`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },
    admissionstaffreport: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/admission",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    pgmsuggestionreport: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/pgmsuggestion?visa="+data.visa+"&qualification="+data.qid+"&appstatus="+data.sid+"&college="+data.college+"&counselor="+data.counselor+"&page="+data.page+"&size="+data.size+"&intake="+data.intake+"&pros_status="+data.prosStatus+"&reg_status="+data.regstatus,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    programSuggestionReportMaster: async () => {
        return apiRequest({
            method:"GET",
            url: `/api/common/report/pgmsuggestion`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    programSuggestionIntakeMaster: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/common/report/intakemaster?visatype=`+data.visatype,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    counselorNIS: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/counselornis",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    countryPerfomance: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/countryperfomance?from="+data.from+'&to='+data.to+'&office='+data.office+'&counselor='+data.counselor,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    onholdReport: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/report/onholdreport`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    readytoMark: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/report/readytomarkreport`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    whatsappConfirmation: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/whatsapp/confirmation`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    validatingApplicationList: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/application/validation/list`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    userImageUpdate: async (data) => {
        return apiRequest({
            method:"PATCH",
            url: `/api/users/image/change/${data.id}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    notproceeding: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/report/notproceeding`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    officevisa: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/officevisa?from="+data.from+'&to='+data.to,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    officefeepaid: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/officefeepaid?from="+data.from+'&to='+data.to,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    topcounselor: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/topcounselor?from="+data.from+'&to='+data.to+'&type='+data.type+'&status='+data.selectedStatus,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    universityfee: async (data) => {
        const size = data.size? data.size: 25
        const page = data.page? data.page: 0
        return apiRequest({
            method:"GET",
            url: `/api/commissions?size=${size}&page=${page}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    updateuniversityfee: async (data) => {
        return apiRequest({
            method:"PUT",
            url: `/api/commissions`,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },
    
    relatedstaffes: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/commissiondetails/applirelstaff/${data}`,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        })
    },

    commissionpayed: async (data) => {
        return apiRequest({
            method:"PUT",
            url: `/api/commissiondetails`,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    commissionreport: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/commissiondetails/commission/report`,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        })
    },

    commissionrepfiltter: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/commissiondetails/filtter/list`,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    relatedapplicants: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/commissiondetails/applications`,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        })
    },

    existingApplicant: async (id) => {
        return apiRequest({
            method:"GET",
            url: `/api/application/exist/applicants/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    updateuniversityfee: async (data) => {
        return apiRequest({
            method:"PUT",
            url: `/api/commissions`,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },
    
    relatedstaffes: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/commissiondetails/applirelstaff/${data}`,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        })
    },

    commissionpayed: async (data) => {
        return apiRequest({
            method:"PUT",
            url: `/api/commissiondetails`,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    commissionreport: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/commissiondetails/commission/report`,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        })
    },

    commissionrepfiltter: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/commissiondetails/filtter/list`,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    relatedapplicants: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/commissiondetails/applications`,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        })
    },




    existingApplicant: async (id) => {
        return apiRequest({
            method:"GET",
            url: `/api/application/exist/applicants/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    changeDocumentOrIeltsStatus: async (data) => {
        return apiRequest({
            method:"PATCH",
            url: `/api/application/documentorielts/changestatus`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    clientexportlist: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/clientlist?from="+data.from+'&to='+data.to+'&reg_status='+data.reg_status+'&pros_status='+data.pros_status+'&counselor='+data.counselor,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    counselorScore: async () => {
        return apiRequest({
            method:"GET",
            url: `/api/score`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    clientexportExcel: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/clientexport?from="+data.from+'&to='+data.to+'&reg_status='+data.reg_status+'&pros_status='+data.pros_status+'&counselor='+data.counselor+'&country='+data.country,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    createCounselorScore: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/score`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },
    clientexportlist: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/clientlist?from="+data.from+'&to='+data.to+'&counselor='+data.counselor+'&Reg='+data.Reg+'&NonReg='+data.NonReg+'&NonRegOnhold='+data.NonRegOnhold+'&RegOnhold='+data.RegOnhold+'&VisaApproved='+data.VisaApproved+'&VisaRefused='+data.VisaRefused+'&notInterested='+data.notInterested+'&notQualified='+data.notQualified,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    clientexportExcel: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/clientexport?from="+data.from+'&to='+data.to+'&counselor='+data.counselor+'&country='+data.country+'&Reg='+data.Reg+'&NonReg='+data.NonReg+'&NonRegOnhold='+data.NonRegOnhold+'&RegOnhold='+data.RegOnhold+'&VisaApproved='+data.VisaApproved+'&VisaRefused='+data.VisaRefused,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    creatingWalkinData: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/walkin/create`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },  

    listWalkinData: async (data) => {
        const size = data.size? data.size: 25
        const page = data.page? data.page: 0
        return apiRequest({
            method:"POST",
            url: `/api/walkin/list?size=${size}&page=${page}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    }, 

    updateWalkinData: async (data) => {
        return apiRequest({
            method:"PUT",
            url: `/api/walkin/update/${data.id}`,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    }, 

    walkinAssign: async (data) => {
        return apiRequest({
            method:"PUT",
            url: `/api/walkin/assing/${data.id}`,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },
    
    listWalkinDataForUser: async (data) => {
        const size = data.size? data.size: 25
        const page = data.page? data.page: 0
        return apiRequest({
            method:"GET",
            url: `/api/walkin/listWalkinsForUser?size=${size}&page=${page}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },    
    
    walkinDelete:  async (id) => {
        return apiRequest({
            method:"DELETE",
            url: `/api/walkin/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

 officefeepaid: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/officefeepaid?from="+data.from+'&to='+data.to,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    adminUserListDrowpdowns: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/common/user/list/dropdown`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    createusa: async (data) => {
        return apiRequest({
            method:"POST",
            url: `/api/usa/create`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    listusa: async (data) => {
        const size = data.size? data.size: 25
        const page = data.page? data.page: 0
       
        return apiRequest({
            method:"POST",
            url: `/api/usa/list?size=${size}&page=${page}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    usaprogramlocation: async (data) => {

        let search = data?.search ? data.search : '';
        let status = data?.status ? data.status : '';
        let page = data?.page ? data.page : 0;
        let size = data?.size ? data.size : 25;
        let target = data?.target ? data.target : '';

        return apiRequest({
            method:"GET",
            url: `/api/usa/location?search=${search}&status=${status}&page=${page}&size=${size}&target=${target}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    usaprogramlocationcreate: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/usa/location/create",
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editUsaLocation: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/usa/location/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteUsaLocation: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/usa/location/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    usaprogramcity: async (data) => {

        let search = data?.search ? data.search : '';
        let status = data?.status ? data.status : '';
        let page = data?.page ? data.page : 0;
        let size = data?.size ? data.size : 1000;
        let target = data?.target ? data.target : '';

        return apiRequest({
            method:"GET",
            url: `/api/usa/city?search=${search}&status=${status}&page=${page}&size=${size}&target=${target}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    usaprogramcitycreate: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/usa/city/create",
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editusacity: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/usa/city/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteusacity: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/usa/city/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    usacityforstate: async (data) => {
        let status = data?.status ? data.status : '';
        let target = data?.target ? data.target : '';
        return apiRequest({
            method:"POST",
            url: `/api/usa/city/cityList/State?status=${status}&target=${target}`,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    usaprogramlevel: async (data) => {

        let search = data?.search ? data.search : '';
        let status = data?.status ? data.status : '';
        let page = data?.page ? data.page : 0;
        let size = data?.size ? data.size : 25;
        let target = data?.target ? data.target : '';

        return apiRequest({
            method:"GET",
            url: `/api/usa/level?search=${search}&status=${status}&page=${page}&size=${size}&target=${target}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    clientexportlist: async (data) => {
  return apiRequest({
      method:"GET",
      url: "/api/report/clientlist?from="+data.from+'&to='+data.to+'&reg_status='+data.reg_status+'&pros_status='+data.pros_status+'&counselor='+data.counselor,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},

clientexportExcel: async (data) => {
  return apiRequest({
      method:"GET",
      url: "/api/report/clientexport?from="+data.from+'&to='+data.to+'&reg_status='+data.reg_status+'&pros_status='+data.pros_status+'&counselor='+data.counselor+'&country='+data.country,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},

    usaprogramlevelcreate: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/usa/level/create",
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    editUsalevel: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/usa/level/" + data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteUsalevel: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/usa/level/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    clientexportlist: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/clientlist?from="+data.from+'&to='+data.to+'&counselor='+data.counselor+'&Reg='+data.Reg+'&NonReg='+data.NonReg+'&NonRegOnhold='+data.NonRegOnhold+'&RegOnhold='+data.RegOnhold+'&VisaApproved='+data.VisaApproved+'&VisaRefused='+data.VisaRefused,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    clientexportExcel: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/clientexport?from="+data.from+'&to='+data.to+'&counselor='+data.counselor+'&country='+data.country+'&Reg='+data.Reg+'&NonReg='+data.NonReg+'&NonRegOnhold='+data.NonRegOnhold+'&RegOnhold='+data.RegOnhold+'&VisaApproved='+data.VisaApproved+'&VisaRefused='+data.VisaRefused,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },


    adminUserListDrowpdowns: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/common/user/list/dropdown`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    walkincreate: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/walkin",
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    walkinStaffList: async (data) => {
        return apiRequest({
            method:"GET",
            url: `api/walkin`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    walkinReport: async (data) => {
        return apiRequest({
            method:"GET",
            url: `api/walkin/report`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    leadstatusList: async (data) => {
        return apiRequest({
            method:"GET",
            url: `api/leadstatus?entry_date=${data.entry_date}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    leadstatuscreate: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/leadstatus",
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    leadstatusReport: async (data) => {
        return apiRequest({
            method:"GET",
            url: `api/leadstatus/report`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },



}


export default authService;
